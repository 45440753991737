import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Loader } from "@googlemaps/js-api-loader";

const DetallesRuta = () => {
  const { id } = useParams(); // Obtener el ID de la ruta desde la URL
  const [detalles, setDetalles] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para manejar el modal
  const [tipoCliente, setTipoCliente] = useState("");
  const [razonesSociales, setRazonesSociales] = useState([]);
  const [formConveniencia, setFormConveniencia] = useState({
    razonSocial: "",
    ciudad: "",
    colonia: "",
    codigoPostal: "",
    calle: "",
    numero: "",
    referencia: "",
    nombreCliente: "",
  });

  const [clientes, setClientes] = useState([]); // Lista completa de clientes
  const [filteredClientes, setFilteredClientes] = useState([]); // Clientes filtrados

  const navigate = useNavigate();
  
      const [usuario, setUsuario] = useState(null);
  
      useEffect(() => {
          const user = JSON.parse(localStorage.getItem('user'));
          if (!user) {
              navigate('/login');  // Si no hay usuario, redirige al login
          } else {
              setUsuario(user);  // Si hay usuario, guarda los datos en el estado
          }
      }, [navigate]);

  // Función para filtrar clientes
  const filterClientes = (query) => {
    if (!query) {
      setFilteredClientes([]); // Limpiar resultados si no hay consulta
      return;
    }
    const filtered = clientes.filter((cliente) =>
      cliente.cliente && cliente.cliente.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredClientes(filtered);
  };

  // Fetch inicial para obtener clientes
  const fetchClientes = async () => {
    try {
      const response = await fetch("https://gavilanes.com.mx/SCRV/clientes/obtener_clientes.php");
      const data = await response.json();
      setClientes(data);
    } catch (error) {
      console.error("Error al obtener los clientes:", error);
    }
  };

  // Fetch de los detalles de la ruta
  const fetchDetallesRuta = async () => {
    try {
      const response = await fetch(`https://gavilanes.com.mx/SCRV/rutas/detalle_ruta.php?id=${id}`);
      const data = await response.json();
      setDetalles(data);
    } catch (error) {
      console.error("Error al obtener detalles de la ruta:", error);
    }
  };

  useEffect(() => {
    fetchDetallesRuta();
    fetchClientes(); // Obtener clientes al cargar el componente
  }, [id]);

  const closeModal = () => {
    setIsModalOpen(false);
    setTipoCliente("");
    setRazonesSociales([]);
    setFormConveniencia({
      razonSocial: "",
      ciudad: "",
      colonia: "",
      codigoPostal: "",
      calle: "",
      numero: "",
      referencia: "",
      nombreCliente: "",
    });
    setFilteredClientes([]); // Limpiar sugerencias de clientes
  };

  const handleAgregarDireccion = () => {
    setIsModalOpen(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormConveniencia((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
      if (name === "nombreCliente") {
        filterClientes(value); // Filtrar clientes mientras se escribe
      }
      return updatedForm;
    });
  };

  const selectCliente = (cliente) => {
    setFormConveniencia((prevForm) => ({
      ...prevForm,
      nombreCliente: cliente.cliente,
    }));
    setFilteredClientes([]); // Limpiar sugerencias
  };

  const handleTipoClienteClick = async (tipo) => {
    setTipoCliente(tipo);
    if (tipo === "detalle") {
      try {
        const response = await fetch("https://gavilanes.com.mx/SCRV/rutas/razon_social.php");
        const data = await response.json();
        const razonesFormateadas = data.map((razon) => ({
          id_sucursal: razon.id_sucursal,
          razon_social: razon.razon_social,
        }));
        setRazonesSociales(razonesFormateadas);
      } catch (error) {
        console.error("Error al obtener razones sociales:", error);
      }
    } else if (tipo === "conveniencia") {
      try {
        const response = await fetch("https://gavilanes.com.mx/SCRV/clientes/obtener_clientes.php");
        const data = await response.json();
        setRazonesSociales([]); // Limpiar razones sociales cuando es "conveniencia"
      } catch (error) {
        console.error("Error al obtener clientes:", error);
      }
    }
  };

  const handleRegistrarDireccion = async () => {
    if (!tipoCliente) {
      alert("Por favor selecciona el tipo de cliente.");
      return;
    }

    let requestBody = {
      idRuta: id, // Agregar el ID de la ruta
      tipoRuta: "",
      idSucursal: null,
      datosConveniencia: null,
    };

    if (tipoCliente === "detalle") {
      if (!formConveniencia.razonSocial) {
        alert("Selecciona una tienda para tipo detalle.");
        return;
      }
      requestBody.tipoRuta = "detalle";
      requestBody.idSucursal = formConveniencia.razonSocial;
    } else if (tipoCliente === "conveniencia") {
      const { razonSocial, ciudad, colonia, codigoPostal, calle, numero, referencia, nombreCliente } = formConveniencia;

      if (!razonSocial || !ciudad || !colonia || !codigoPostal || !calle || !numero || !nombreCliente) {
        alert("Por favor completa todos los campos obligatorios para conveniencia.");
        return;
      }

      requestBody.tipoRuta = "conveniencia";
      requestBody.datosConveniencia = {
        razonSocial,
        ciudad,
        colonia,
        codigoPostal,
        calle,
        numero,
        referencia,
        nombreCliente,
      };
    }

    try {
      const response = await fetch("https://gavilanes.com.mx/SCRV/rutas/agregar_direccion.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          closeModal();
          fetchDetallesRuta(); // Actualizar lista de detalles.
        } else {
          alert(result.error || "Error al registrar la dirección.");
        }
      } else {
        console.error("Error al guardar dirección:", response.statusText);
      }
    } catch (error) {
      console.error("Error de conexión:", error);
    }
  };

  if (!detalles) {
    return <div style={styles.loading}>Cargando detalles...</div>;
  }

  const puntoFijo = { lat: 20.529049, lng: -100.428674 };
    
  const calcularRutaOptima = async () => {
    const loader = new Loader({
      apiKey: "AIzaSyAw_THAYZ552Vsc1333iGxAH6oBREKFapA",
      version: "weekly",
      libraries: ["places"],
    });
  
    try {
      const googleMaps = await loader.load(); // Carga la API de Google Maps y asegura acceso a `google`
      const directionsService = new googleMaps.maps.DirectionsService();
  
      // Crea los waypoints basados en las sucursales
      const waypoints = detalles.sucursales.map((sucursal) => ({
        location: `${sucursal.calle} ${sucursal.numero}, ${sucursal.ciudad}, ${sucursal.nombre_colonia}`,
        stopover: true,
      }));
  
      // Configuración de la solicitud para la ruta
      const request = {
        origin: puntoFijo,
        destination: puntoFijo,
        waypoints: waypoints,
        travelMode: googleMaps.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      };
  
      // Calcula la ruta óptima
      directionsService.route(request, (result, status) => {
        if (status === googleMaps.maps.DirectionsStatus.OK) {
          const orderedWaypoints = result.routes[0].waypoint_order.map(
            (index) => detalles.sucursales[index]
          );
          generarPDF(orderedWaypoints);
        } else {
          console.error(`Error al calcular la ruta: ${status}`);
        }
      });
    } catch (error) {
      console.error("Error al cargar la API de Google Maps o calcular la ruta:", error);
    }
  };
  
    
      const generarPDF = (orderedSucursales) => {
        const doc = new jsPDF('landscape');
        
        // Configuración del título del reporte
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(15);
        doc.setTextColor(40);
        doc.text('Reporte de Ruta', 14, 20);
      
        // Datos de la ruta
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(11);
        doc.setTextColor(60);
        doc.text(`ID Ruta: ${detalles.id}`, 14, 30);
        doc.text(`Ruta: ${detalles.nombre_ruta}`, 14, 36);
      
        // Línea decorativa
        doc.setDrawColor(22, 160, 133); // Verde
        doc.setLineWidth(1);
        doc.line(14, 42, 280, 42);
      
        // Configuración de la tabla
        const columns = [
          { header: 'Tipo de Servicio', dataKey: 'tipo_cliente' },
          { header: 'Cliente', dataKey: 'id_cliente' },
          { header: 'Tienda', dataKey: 'razon_social' },
          { header: 'Ciudad', dataKey: 'ciudad' },
          { header: 'Colonia', dataKey: 'nombre_colonia' },
          { header: 'Calle', dataKey: 'calle' },
          { header: 'Número', dataKey: 'numero' },
          { header: 'Referencia', dataKey: 'referencia' },
        ];
      
        const rows = orderedSucursales.map((sucursal) => ({
          tipo_cliente: sucursal.cliente || sucursal.tipo_cliente || "Detalle",
          id_cliente: sucursal.id_cliente || "N/A",
          razon_social: sucursal.razon_social || "Sin tienda",
          ciudad: sucursal.ciudad || "Sin ciudad",
          nombre_colonia: sucursal.nombre_colonia || "Sin colonia",
          calle: sucursal.calle || "Sin calle",
          numero: sucursal.numero || "Sin número",
          referencia: sucursal.referencia || "Sin referencia",
        }));
      
        doc.autoTable({
          startY: 50,
          head: [columns.map((col) => col.header)],
          body: rows.map((row) => columns.map((col) => row[col.dataKey])),
          theme: 'striped',
          styles: { fontSize: 7, cellPadding: 1 },
          headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255], fontStyle: 'bold' },
          alternateRowStyles: { fillColor: [240, 248, 255] },
          margin: { top: 50 },
        });
      
        // Pie de página con fecha
        const fecha = new Date().toLocaleString();
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(7);
        doc.setTextColor(100);
        doc.text(`Reporte generado el: ${fecha}`, 14, doc.internal.pageSize.height - 10);
      
        // Guardar el PDF
        doc.save(`ReporteRuta_${detalles.id}.pdf`);
      };
  

  return (
    <div style={styles.container}>
      <button onClick={calcularRutaOptima} style={styles.pdfButton}>Generar Reporte PDF</button>
      <h1 style={styles.title}>Ruta: {detalles.nombre_ruta || "Sin nombre disponible"}</h1>
      <div style={styles.header}>
        <h3>
          ID Ruta: <strong>{detalles.id}</strong>
        </h3>
        {usuario && usuario.tipo_usuario === '1' && (
          <button style={styles.addButton} onClick={handleAgregarDireccion}>
            Agregar Dirección
          </button>
        )}
      </div>

      <div style={styles.tableContainer}>
        <h2 style={styles.subtitle}>Sucursales Asociadas</h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th>Tipo de Servicio</th>
              <th>Cliente</th>
              <th>Tienda</th>
              <th>Ciudad</th>
              <th>Colonia</th>
              <th>Calle</th>
              <th>Número</th>
              <th>Referencia</th>
              {usuario && usuario.tipo_usuario === '1' && (
                <th>Acciones</th>
              )}
            </tr>
          </thead>
          <tbody>
            {detalles.sucursales.map((sucursal, index) => (
              <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                <td>{sucursal.cliente || sucursal.tipo_cliente || "Detalle"}</td>
                <td>{sucursal.id_cliente || "N/A"}</td>
                <td>{sucursal.razon_social || "Sin tienda"}</td>
                <td>{sucursal.ciudad || "Sin ciudad"}</td>
                <td>{sucursal.nombre_colonia || "Sin colonia"}</td>
                <td>{sucursal.calle || "Sin calle"}</td>
                <td>{sucursal.numero || "Sin número"}</td>
                <td>{sucursal.referencia || "Sin referencia"}</td>
                <td style={styles.actions}>
                  {usuario && usuario.tipo_usuario === '1' && (
                    <button style={styles.editButton}>✏️</button>
                  )}
                  {usuario && usuario.tipo_usuario === '1' && (
                    <button style={styles.deleteButton}>🗑️</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h3 style={styles.modalTitle}>Selecciona el tipo de cliente</h3>
            <div style={styles.modalButtons}>
              <button
                style={{
                  ...styles.modalButton,
                  ...(tipoCliente === "detalle" ? styles.selectedButton : {}),
                }}
                onClick={() => handleTipoClienteClick("detalle")}
              >
                Detalle
              </button>
              <button
                style={{
                  ...styles.modalButton,
                  ...(tipoCliente === "conveniencia" ? styles.selectedButton : {}),
                }}
                onClick={() => handleTipoClienteClick("conveniencia")}
              >
                Otro
              </button>
            </div>
            {tipoCliente === "detalle" && (
              <div style={styles.selectContainer}>
                <h4>Tiendas:</h4>
                <select
                  style={styles.select}
                  value={formConveniencia.razonSocial}
                  onChange={(e) => setFormConveniencia({ ...formConveniencia, razonSocial: e.target.value })}
                >
                  <option value="">Selecciona una tienda</option>
                  {razonesSociales.map((razon) => (
                    <option key={razon.id_sucursal} value={razon.id_sucursal}>
                      {razon.razon_social}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {tipoCliente === "conveniencia" && (
              <form style={styles.form}>
                {["razonSocial", "ciudad", "colonia", "codigoPostal", "calle", "numero", "referencia", "nombreCliente"].map(
                  (field) => (
                    <div key={field} style={styles.inputContainer}>
                      <label style={styles.label}>{field}</label>
                      <input
                        type="text"
                        name={field}
                        value={formConveniencia[field]}
                        onChange={handleFormChange}
                        style={styles.input}
                      />
                      {field === "nombreCliente" && filteredClientes.length > 0 && (
                        <ul style={styles.suggestionsList}>
                        {filteredClientes.map((cliente) => (
                          <li
                            key={cliente.id_cliente}
                            onClick={() => selectCliente(cliente)}
                            style={styles.suggestionItem}
                            onMouseEnter={(e) => (e.target.style.backgroundColor = "#f0f8ff")}
                            onMouseLeave={(e) => (e.target.style.backgroundColor = "white")}
                          >
                            {cliente.cliente || "Sin nombre"}
                          </li>
                        ))}
                      </ul>
                      )}
                    </div>
                  )
                )}
              </form>
            )}
            <div style={styles.modalActions}>
              <button style={styles.closeButton} onClick={closeModal}>
                Cancelar
              </button>
              <button style={styles.saveButton} onClick={handleRegistrarDireccion}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "30px",
    fontFamily: "'Roboto', sans-serif",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "1200px",
    margin: "30px auto",
  },
  suggestionsList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    maxHeight: "200px", // Altura máxima para activar el scroll si es necesario
    overflowY: "auto", // Habilitar scroll vertical
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Sombra para resaltar
    borderRadius: "5px",
    border: "1px solid #ddd",
  },
  suggestionItem: {
    padding: "10px",
    borderBottom: "1px solid #eee",
    cursor: "pointer",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "28px",
    color: "#333",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    paddingBottom: "10px",
    borderBottom: "2px solid #f1f1f1",
  },
  tableContainer: {
    marginTop: "20px",
    backgroundColor: "#f9f9f9",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  subtitle: {
    fontSize: "22px",
    marginBottom: "15px",
    color: "#444",
    fontWeight: "600",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
  },
  th: {
    backgroundColor: "#f4f4f4",
    padding: "12px",
    textAlign: "center", // Centrado horizontalmente
    verticalAlign: "middle", // Centrado verticalmente
    fontWeight: "600",
    color: "#333",
    borderBottom: "1px solid #ddd",
  },
  td: {
    padding: "12px",
    textAlign: "center", // Centrado horizontalmente
    verticalAlign: "middle", // Centrado verticalmente
    fontSize: "14px",
    color: "#666",
    borderBottom: "1px solid #ddd",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
  editButton: {
    backgroundColor: "#FFD700",
    border: "none",
    borderRadius: "4px",
    padding: "8px 12px",
    cursor: "pointer",
    marginRight: "8px",
    fontSize: "16px",
    color: "#fff",
  },
  deleteButton: {
    backgroundColor: "#FF6347",
    border: "none",
    borderRadius: "4px",
    padding: "8px 12px",
    cursor: "pointer",
    fontSize: "16px",
    color: "#fff",
  },
  downloadButton: {
    display: "block",
    margin: "30px auto 0",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "6px",
    padding: "12px 25px",
    fontSize: "18px",
    cursor: "pointer",
    textAlign: "center",
  },
  loading: {
    textAlign: "center",
    fontSize: "20px",
    color: "#888",
  },
  evenRow: {
    backgroundColor: "#fafafa",
  },
  oddRow: {
    backgroundColor: "#fff",
  },
  addButton: {
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "10px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#333",
  },
  inputContainer: {
    marginBottom: "10px",
    textAlign: "left",
  },
  label: {
    display: "block",
    textAlign: "left",
    fontSize: "16px",
    marginBottom: "5px",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    textAlign: "left",
  },
  modalButtons: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  modalButton: {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  selectedButton: {
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
  },
  selectContainer: {
    marginBottom: "20px",
    textAlign: "left",
  },
  select: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
    marginTop: "10px",
  },
  modalActions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  registerButton: {
    padding: "10px 20px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.3s ease",
  },
  cancelButton: {
    padding: "10px 20px",
    backgroundColor: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.3s ease",
  },
  cancelButtonHover: {
    backgroundColor: "#d32f2f",
  },

  // Media Queries for responsiveness
  "@media (max-width: 768px)": {
    container: {
      padding: "15px",
      margin: "15px auto",
    },
    title: {
      fontSize: "24px",
    },
    header: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "10px",
    },
    tableContainer: {
      padding: "10px",
    },
    table: {
      fontSize: "12px", // Reducir el tamaño de fuente para pantallas pequeñas
    },
    th: {
      padding: "10px",
    },
    td: {
      padding: "10px",
    },
    modal: {
      padding: "20px",
      maxWidth: "90%",
    },
    input: {
      padding: "6px",
    },
    modalButton: {
      fontSize: "14px",
    },
  },
};


export default DetallesRuta;
