import React from 'react';
import { useNavigate } from 'react-router-dom';

const Reportes = () => {
    const navigate = useNavigate();

    const handleReporteDiario = () => {
        // Lógica para generar el reporte diario de venta
        navigate('/reporte-diario');
    };

    const handleReporteTiendasDetalle = () => {
        // Lógica para generar el reporte de tiendas de detalle
        navigate('/reporte-tiendas-detalle');
    };

    const handleReporteConveniencia = () => {
        // Lógica para generar el reporte de tiendas de conveniencia
        navigate('/reporte-conveniencia');
    };

    const handleReporteAutoservicio = () => {
        // Lógica para generar el reporte de tiendas de autoservicio
        navigate('/reporte-autoservicio');
    };

    const handleReporteVentaGeneral = () => {
        // Lógica para generar el reporte de venta general
        navigate('/reporte-venta-general');
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Reportes</h2>
            <button style={styles.button} onClick={handleReporteDiario}>Reporte Diario de Venta</button>
            <button style={styles.button} onClick={handleReporteTiendasDetalle}>Reporte de Tiendas de Detalle</button>
            <button style={styles.button} onClick={handleReporteConveniencia}>Reporte de Tiendas de Conveniencia</button>
            <button style={styles.button} onClick={handleReporteAutoservicio}>Reporte de Tiendas de Autoservicio</button>
            <button style={styles.button} onClick={handleReporteVentaGeneral}>Reporte de Venta General</button>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '600px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    button: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
        textAlign: 'center',
    },
};

export default Reportes;