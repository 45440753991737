import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/logo-gavilanes.png';
import { useRef } from 'react';
import './navegacion.css'; // Importar el archivo CSS

const Navegacion = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar la visibilidad del menú

    const timeoutIdRef = useRef(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        }

        const handleActivity = () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
            timeoutIdRef.current = setTimeout(handleLogout, 10 * 60 * 1000); // 1000 = 10 minutos - 100 = 1 minuto
        };

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('click', handleActivity);
        window.addEventListener('keypress', handleActivity);

        handleActivity();

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('click', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('user'); // Elimina los datos del usuario del localStorage
        navigate('/login'); // Redirige a la página de inicio de sesión
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Cambia el estado para abrir o cerrar el menú
    };

    return (
        <nav className="navbar">
            <div className="logo-container">
                <Link to="/">
                    <img className="logo" src={logo} alt="Logo" />
                </Link>
            </div>

            {/* Botón de hamburguesa para pantallas pequeñas */}
            <button onClick={toggleMenu} className="menu-button">
                &#9776;
            </button>

            {/* Menú desplegable en dispositivos pequeños */}
            <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <li>
                    <Link to="/reportes" className="link">
                        <button className="button">Reportes</button>
                    </Link>
                </li>
                <li>
                    <Link to="/historial_acciones" className="link">
                        <button className="button">Acciones</button>
                    </Link>
                </li>
                <li>
                    <Link to="/Usuarios" className="link">
                        <button className="button">Usuarios</button>
                    </Link>
                </li>
                <li>
                    <Link to="/rutas" className="link">
                        <button className="button">Rutas</button>
                    </Link>
                </li>
                <li>
                    <Link to="/RegistrarVenta" className="link">
                        <button className="button">Registrar Venta</button>
                    </Link>
                </li>
                <li>
                    <Link to="/clientes" className="link">
                        <button className="button">Clientes/Sucursales</button>
                    </Link>
                </li>
                <li>
                    <Link to="/productos" className="link">
                        <button className="button">Productos</button>
                    </Link>
                </li>
                <li>
                    <button onClick={handleLogout} className="button">Cerrar Sesión</button>
                </li>
            </ul>
        </nav>
    );
};

export default Navegacion;
