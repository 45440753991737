import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ReporteDiario = () => {
    const [fecha, setFecha] = useState(new Date().toISOString().split('T')[0]);
    const [ventas, setVentas] = useState([]);
    const [error, setError] = useState('');

    const obtenerRazonSocial = async (idSucursal) => {
        try {
            const response = await axios.get(`https://gavilanes.com.mx/SCRV/reportes/obtener_sucursal.php?id_sucursal=${idSucursal}`);
            return response.data.razon_social;
        } catch (error) {
            console.error(`Error al obtener la razón social de la sucursal ${idSucursal}:`, error);
            return `Sucursal ${idSucursal}`;
        }
    };

    const fetchVentas = async (fecha) => {
        try {
            setVentas([]);
            const responseDetalle = await axios.get(`https://gavilanes.com.mx/SCRV/reportes/obtener_ventas_detalle.php?fecha=${fecha}`);
            const responseConveniencia = await axios.get(`https://gavilanes.com.mx/SCRV/reportes/obtener_ventas_conveniencia.php?fecha=${fecha}`);
            const responseAutoservicio = await axios.get(`https://gavilanes.com.mx/SCRV/reportes/obtener_ventas_autoservicio.php?fecha=${fecha}`);

            const todasLasVentas = [
                ...responseDetalle.data.map(venta => ({ ...venta, tipo: 'Detalle' })),
                ...responseConveniencia.data.map(venta => ({ ...venta, tipo: 'Conveniencia' })),
                ...responseAutoservicio.data.map(venta => ({ ...venta, tipo: 'Autoservicio' }))
            ];

            const ventasConSucursal = await Promise.all(
                todasLasVentas.map(async (venta) => {
                    const razonSocial = await obtenerRazonSocial(venta.id_sucursal);
                    return { ...venta, razon_social: razonSocial };
                })
            );

            const ventasAgrupadas = agruparVentasPorClienteYFolio(ventasConSucursal);
            setVentas(ventasAgrupadas);
        } catch (error) {
            setError('Error al obtener las ventas: ' + error.message);
        }
    };

    const agruparVentasPorClienteYFolio = (ventas) => {
        const agrupadas = ventas.reduce((acc, venta) => {
            const key = `${venta.folio}-${venta.id_cliente}-${venta.id_sucursal}`;
            if (!acc[key]) {
                acc[key] = {
                    folio: venta.folio,
                    cliente: venta.cliente,
                    id_cliente: venta.id_cliente,
                    razon_social: venta.razon_social,
                    tipo: venta.tipo,
                    notas: venta.notas,
                    productos: [],
                };
            }

            acc[key].productos.push({
                nombre: venta.producto,
                cantidad: venta.cantidad,
                precio: parseFloat(venta.precio),
                subtotal: venta.cantidad * parseFloat(venta.precio),
            });

            return acc;
        }, {});

        const agrupadasArray = Object.values(agrupadas);

        return agrupadasArray.sort((a, b) => {
            const order = ['Detalle', 'Conveniencia', 'Autoservicio'];
            return order.indexOf(a.tipo) - order.indexOf(b.tipo);
        });
    };

    useEffect(() => {
        fetchVentas(fecha);
    }, [fecha]);

    const handleDateChange = (e) => {
        setFecha(e.target.value);
    };

    const calcularTotal = (ventas) => {
        return ventas.reduce(
            (acc, venta) =>
                acc + venta.productos.reduce((subAcc, prod) => subAcc + prod.subtotal, 0),
            0
        );
    };

    const handleNotaChange = async (e, venta) => {
        const nuevaNota = e.target.value;

        setVentas(prevVentas =>
            prevVentas.map(v =>
                v.folio === venta.folio && v.id_cliente === venta.id_cliente && v.id_sucursal === venta.id_sucursal
                    ? { ...v, notas: nuevaNota }
                    : v
            )
        );

        try {
            await axios.post('https://gavilanes.com.mx/SCRV/reportes/guardar_nota.php', {
                folio: venta.folio,
                id_cliente: venta.id_cliente,
                notas: nuevaNota,
            });
        } catch (error) {
            console.error('Error al guardar la nota:', error);
            setError('Error al guardar la nota');
        }
    };

    const generarPDF = async () => {
        const input = document.getElementById('reporteDiario');
        const canvas = await html2canvas(input, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        
        const pdf = new jsPDF('landscape', 'mm', 'a4');
        const margin = 10; // Márgenes de 10 mm
        const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2; // Ancho con márgenes
        const pdfHeight = pdf.internal.pageSize.getHeight() - margin * 2; // Altura con márgenes
    
        // Agregar el título
        pdf.setFontSize(16);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Reporte diario de ventas', pdf.internal.pageSize.getWidth() / 2, margin + 5, { align: 'center' });
    
        const titleHeight = 15;
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
        let heightLeft = imgHeight;
        let position = margin + titleHeight;
    
        pdf.addImage(imgData, 'PNG', margin, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;
    
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', margin, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;
        }
    
        pdf.save(`ReporteDiarioVentas_${fecha}.pdf`);
    };
    
    
    const renderVentasPorTipo = (tipo) => {
        const ventasPorTipo = ventas.filter(venta => venta.tipo === tipo);
        if (ventasPorTipo.length === 0) return null;

        return (
            <>
                <h3 style={styles.subHeader}>Ventas - {tipo}</h3>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th>Folio de Venta</th>
                            <th>Código del Cliente</th>
                            <th>Cliente</th>
                            <th>Sucursal</th>
                            <th>Productos</th>
                            <th>Subtotal</th>
                            <th>Notas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ventasPorTipo.map((venta) => (
                            <tr key={`${venta.folio}-${venta.id_cliente}-${venta.id_sucursal}`}>
                                <td style={styles.td}>{venta.folio}</td>
                                <td style={styles.td}>{venta.id_cliente}</td>
                                <td style={styles.td}>{venta.cliente}</td>
                                <td style={styles.td}>{venta.razon_social}</td>
                                <td style={styles.td}>
                                    <ul style={styles.productList}>
                                        {venta.productos.map((prod, index) => (
                                            <li key={index}>
                                                +{prod.nombre} | Cantidad: {prod.cantidad} | Precio: {prod.precio} | Subtotal: {prod.subtotal}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td style={styles.td}>
                                    {venta.productos.reduce((acc, prod) => acc + prod.subtotal, 0)}
                                </td>
                                <td style={styles.td}>
                                    <textarea
                                        value={venta.notas}
                                        onChange={(e) => handleNotaChange(e, venta)}
                                        style={styles.textarea}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    };

    return (
        <div style={styles.container}>
            <button onClick={generarPDF} style={styles.pdfButton}>Descargar Reporte</button>
            <h2 style={styles.header}>Reporte Diario de Venta</h2>
            <div style={styles.dateContainer}>
                <label style={styles.label}>Fecha:</label>
                <input
                    type="date"
                    value={fecha}
                    onChange={handleDateChange}
                    style={styles.input}
                />
            </div>
            {error && <p style={styles.error}>{error}</p>}

            <div id="reporteDiario">
                {renderVentasPorTipo('Detalle')}
                {renderVentasPorTipo('Conveniencia')}
                {renderVentasPorTipo('Autoservicio')}

                <p style={styles.total}>Total: ${calcularTotal(ventas)}</p>
            </div>

            
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '95%',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    dateContainer: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    label: {
        marginRight: '10px',
        fontWeight: 'bold',
    },
    textarea: {
        width: '100%',
        height: '40px',
        resize: 'none',
        padding: '1px',
        borderRadius: '5px',
        border: '1px solid #ddd',
    },
    input: {
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid #ddd',
    },
    subHeader: {
        marginTop: '20px',
        marginBottom: '10px',
        color: '#333',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: '#fff',
        marginBottom: '20px',
    },
    td: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'center',
        color: '#333',
    },
    productList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        textAlign: 'left',
    },
    total: {
        textAlign: 'right',
        fontWeight: 'bold',
        marginTop: '10px',
        color: '#333',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
    pdfButton: {
        display: 'block',
        width: '13%',
        padding: '10px',
        marginTop: '20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
        textAlign: 'center',
    },
};

export default ReporteDiario;
