import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';

const Usuarios = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentUsuario, setCurrentUsuario] = useState({
        nombre: '',
        correo: '',
        contrasena: '',
        estatus: '1',
        tipo_usuario: '1'
    });
    const navigate = useNavigate();

    useEffect(() => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                navigate('/login');
            } else if (user.tipo_usuario != '1') {
                navigate('/rutas');
            }
    }, [navigate]);

    const fetchUsuarios = async () => {
        try {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/usuarios/usuarios.php');
            setUsuarios(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching users:', error);
            setUsuarios([]);
        }
    };

    useEffect(() => {
        fetchUsuarios();
    }, []);

    const toggleModal = (usuario = null) => {
        if (usuario) {
            setCurrentUsuario(usuario);
            setIsEditing(true);
        } else {
            setCurrentUsuario({
                nombre: '',
                correo: '',
                contrasena: '',
                estatus: '1',
                tipo_usuario: '1'
            });
            setIsEditing(false);
        }
        setShowModal(!showModal);
    };

    const handleSave = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const endpoint = isEditing
                ? 'https://gavilanes.com.mx/SCRV/usuarios/cambiarPermiso.php'
                : 'https://gavilanes.com.mx/SCRV/usuarios/registrarUsuario.php';
    
            const response = await axios.post(endpoint, {
                id: currentUsuario.id_usuario,
                nombre: currentUsuario.nombre,
                correo: currentUsuario.correo,
                contrasena: currentUsuario.contrasena,
                estatus: currentUsuario.estatus,
                tipo_usuario: currentUsuario.tipo_usuario
            });
    
            if (response.data.success) {
                // Llamar al endpoint agregarAccion
                const accion = isEditing
                    ? `Cambio de permiso para: ${currentUsuario.nombre}`
                    : `Registro de nuevo usuario: ${currentUsuario.nombre}`;
                
                await axios.post('https://gavilanes.com.mx/SCRV/historial_acciones/agregarAccion.php', {
                    id_usuario: user.id_usuario,
                    accion: accion,
                    fecha_accion: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
                });
    
                // Actualiza la lista de usuarios o realiza cualquier otra acción necesaria
                fetchUsuarios();
                toggleModal();
            } else {
                console.error('Error saving user:', response.data.message);
            }
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const cambiarEstado = async (usuario) => {
        const nuevoEstado = usuario.estatus === '1' ? '0' : '1';
        try {
            await axios.post('https://gavilanes.com.mx/SCRV/usuarios/cambiarEstado.php', {
                id_usuario: usuario.id_usuario,
                estatus: nuevoEstado,
            });

            setUsuarios(usuarios.map((u) =>
                u.id_usuario === usuario.id_usuario ? { ...u, estatus: nuevoEstado } : u
            ));
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Usuarios Registrados</h2>
            <button style={styles.addButton} onClick={() => toggleModal()}>
                Registrar Nuevo Usuario
            </button>
            <div style={styles.cardContainer}>
                {usuarios.map((usuario) => (
                    <div key={usuario.id_usuario} style={styles.card}>
                        <h3 style={styles.usuarioName}>{usuario.nombre}</h3>
                        <p
                            style={
                                usuario.estatus === '1' ? styles.activeStatus : styles.inactiveStatus
                            }
                        >
                            {usuario.estatus === '1' ? 'Activo' : 'Inactivo'}
                        </p>
                        <p style={styles.permiso}>
                            Permiso: {usuario.tipo_usuario === '1' ? 'Administrador' : usuario.tipo_usuario === '2' ? 'Repartidor' : 'Desconocido'}
                        </p>
                        <div style={styles.actions}>
                            <button style={styles.editButton} onClick={() => toggleModal(usuario)}>
                                Modificar
                            </button>
                            <button
                                style={styles.statusButton}
                                onClick={() => cambiarEstado(usuario)}
                            >
                                {usuario.estatus === '1' ? 'Dar de Baja' : 'Dar de Alta'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {showModal && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <h2>{isEditing ? 'Modificar Permiso' : 'Registrar Nuevo Usuario'}</h2>

                        {isEditing ? (
                            <div style={styles.inputGroup}>
                                <label style={styles.label}>Permiso</label>
                                <select
                                    style={styles.input}
                                    name="permiso"
                                    value={currentUsuario.tipo_usuario}
                                    onChange={(e) =>
                                        setCurrentUsuario({ ...currentUsuario, tipo_usuario: e.target.value })
                                    }
                                >
                                    <option value="1">Administrador</option>
                                    <option value="2">Repartidor</option>
                                </select>
                            </div>
                        ) : (
                            <>
                                <div style={styles.inputGroup}>
                                    <label style={styles.label}>Nombre</label>
                                    <input
                                        style={styles.input}
                                        type="text"
                                        name="nombre"
                                        value={currentUsuario.nombre}
                                        onChange={(e) =>
                                            setCurrentUsuario({ ...currentUsuario, nombre: e.target.value })
                                        }
                                        placeholder="Nombre"
                                    />
                                </div>
                                <div style={styles.inputGroup}>
                                    <label style={styles.label}>Correo</label>
                                    <input
                                        style={styles.input}
                                        type="email"
                                        name="correo"
                                        value={currentUsuario.correo}
                                        onChange={(e) =>
                                            setCurrentUsuario({ ...currentUsuario, correo: e.target.value })
                                        }
                                        placeholder="Correo"
                                    />
                                </div>
                                <div style={styles.inputGroup}>
                                    <label style={styles.label}>Contraseña</label>
                                    <input
                                        style={styles.input}
                                        type="password"
                                        name="contrasena"
                                        value={currentUsuario.contrasena}
                                        onChange={(e) =>
                                            setCurrentUsuario({ ...currentUsuario, contrasena: e.target.value })
                                        }
                                        placeholder="Contraseña"
                                    />
                                </div>
                                <div style={styles.inputGroup}>
                                    <label style={styles.label}>Permiso</label>
                                    <select
                                        style={styles.input}
                                        name="permiso"
                                        value={currentUsuario.tipo_usuario}
                                        onChange={(e) =>
                                            setCurrentUsuario({ ...currentUsuario, tipo_usuario: e.target.value })
                                        }
                                    >
                                        <option value="1">Administrador</option>
                                        <option value="2">Repartidor</option>
                                    </select>
                                </div>
                            </>
                        )}
                        <div style={styles.modalActions}>
                            <button style={styles.saveButton} onClick={handleSave}>Guardar</button>
                            <button style={styles.cancelButton} onClick={() => toggleModal()}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#f4f5f7',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        maxWidth: '1200px',
        margin: '20px auto',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '30px',
        textAlign: 'center',
    },
    addButton: {
        backgroundColor: '#3498db',
        color: 'white',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '30px',
        cursor: 'pointer',
        fontSize: '16px',
        display: 'block',
        margin: '0 auto 40px auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.3s ease',
    },
    cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
    },
    card: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
    },
    usuarioName: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '10px',
    },
    permiso: {
        color: '#7f8c8d',
        marginBottom: '10px',
    },
    activeStatus: {
        color: '#27ae60',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    inactiveStatus: {
        color: '#e74c3c',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    editButton: {
        backgroundColor: '#f39c12',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
    statusButton: {
        backgroundColor: '#e74c3c',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
    modalOverlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
        width: '400px',
        maxHeight: '80vh',
        overflowY: 'auto',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    saveButton: {
        backgroundColor: '#2ecc71',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    cancelButton: {
        backgroundColor: '#e74c3c',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
};

export default Usuarios;
