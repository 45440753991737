import React from 'react';
// Importa el CSS de Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
// Opcional: Íconos de Bootstrap
import 'bootstrap-icons/font/bootstrap-icons.css';

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './vistas/navegacion';
import Charts from './graficas/charts';
import Clientes from './vistas/clientes/clientes';
import RegistroCliente from './vistas/clientes/registroCliente';
import Productos from './vistas/productos/productos';
import DetalleProducto from './vistas/productos/DetalleProducto';
import RegistrarVenta from './vistas/ventas/registrar-venta';
import VentasRegistradas from './vistas/ventas/ventas-registradas';
import DetalleVenta from './vistas/ventas/detalle-venta';
import Rutas from './vistas/rutas/rutas';
import DetalleRuta from './vistas/rutas/detalleRuta';
import Mapeo from './vistas/rutas/mapeo';
import Login from './login';
import Footer from './vistas/footer';
import Usuarios from './vistas/Usuarios/usuarios';
import Sucursales from './vistas/sucursales/sucursales';
import HistorialAcciones from './vistas/historial_acciones/historial_acciones';
import Reportes from './vistas/reportes/reportes';
import ReporteDiario from './vistas/reportes/reporte-diario';
import Aniversario from './vistas/aniversario/home';
import Contra from './vistas/aniversario/contra';


function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

function AppContent() {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login' || location.pathname === '/home';

    return (
        <div style={styles.appStyle}>
            {!isLoginPage && <Navbar />}
            <main style={styles.mainStyle}>
                <Routes>
                    <Route path="/" element={<Charts />} />
                    <Route path="/clientes" element={<Clientes />} />
                    <Route path="/productos" element={<Productos />} />
                    <Route path="/registroClientes" element={<RegistroCliente />} />
                    <Route path="/registrarVenta" element={<RegistrarVenta />} />
                    <Route path="/VentasRegistradas" element={<VentasRegistradas />} />
                    <Route path="/detalle-venta/:id_venta" element={<DetalleVenta />} />
                    <Route path="/sucursales/:id_cliente" element={<Sucursales />} />
                    <Route path="/producto/:id" element={<DetalleProducto />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/rutas" element={<Rutas />} />
                    <Route path="/detalleRuta/:id" element={<DetalleRuta />} />
                    <Route path="/mapeo/:idRuta" element={<Mapeo />} />
                    <Route path="/usuarios" element={<Usuarios />} />
                    <Route path="/historial_acciones" element={<HistorialAcciones />} />
                    <Route path="/reportes" element={<Reportes />} />
                    <Route path="/reporte-diario" element={<ReporteDiario />} />
                    <Route path="/home" element={<Aniversario />} />
                    <Route path="/contra" element={<Contra />} />
                </Routes>
            </main>
            {!isLoginPage && <Footer />}
        </div>
    );
}


const styles = {
    appStyle: {
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    mainStyle: {
        padding: '20px',
        flexGrow: 1,
    }
};

export default App;
