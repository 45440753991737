// src/components/Charts.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import funnelChart from '../images/chart1.png';
import pieChart1 from '../images/chart2.jpg';
import pieChart2 from '../images/chart3.jpg';
import genderChart from '../images/chart4.jpg';


const Charts = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                navigate('/login');
            } else if (user.tipo_usuario != '1') {
                navigate('/rutas'); // Redirige a la vista "rutas" si el tipo de usuario es '2'
            }
        }, [navigate]);

    return (
        <div style={styles.chartsContainerStyle}>
            <div style={styles.chartRowStyle}>
                <div style={styles.chartBoxStyle}>
                    <img style={styles.imgStyle} src={funnelChart} alt="Funnel Chart" />
                </div>
                <div style={styles.chartBoxStyle}>
                    <img style={styles.imgStyle} src={genderChart} alt="Gender Distribution Chart" />
                </div>
            </div>
            <div style={styles.chartRowStyle}>
                <div style={styles.chartBoxStyle}>
                    <img style={styles.imgStyle} src={pieChart1} alt="Pie Chart 1" />
                </div>
                <div style={styles.chartBoxStyle}>
                    <img style={styles.imgStyle} src={pieChart2} alt="Pie Chart 2" />
                </div>
            </div>
        </div>
    );
};

const styles = {
    chartsContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px',
    },
    chartRowStyle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    chartBoxStyle: {
        flex: '1',
        margin: '0 10px',
        padding: '10px',
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
    },
    imgStyle: {
        width: '100%',
        borderRadius: '8px',
    }
};

export default Charts;
