import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const DetalleVenta = () => {
    const { id_venta } = useParams();
    const [detalle, setDetalle] = useState([]);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDetalle = async () => {
            try {
                const response = await axios.get(`https://gavilanes.com.mx/SCRV/ventas/obtenerDetalleVenta.php?id_venta=${id_venta}`);
                setDetalle(response.data.detalle || []);
                setTotal(response.data.total || 0);
            } catch (error) {
                console.error('Error fetching sale details:', error);
            }
        };

        fetchDetalle();
    }, [id_venta]);

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Detalle de Venta</h2>
            <div style={styles.tableContainer}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Precio Unitario</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {detalle.map((item) => (
                            <tr key={item.id_detalle}>
                                <td style={styles.td}>{item.nombre_producto}</td>
                                <td style={styles.td}>{item.cantidad}</td>
                                <td style={styles.td}>{"$" + item.precio_unitario}</td>
                                <td style={styles.td}>{"$" + (item.cantidad * item.precio_unitario)}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="3" style={styles.td}>Total</td>
                            <td style={styles.td}>{"$" + total}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button style={styles.backButton} onClick={() => navigate('/VentasRegistradas')}>
                Regresar
            </button>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: '#fff',
    },
    th: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'center',
        backgroundColor: '#f2f2f2',
        color: '#333',
        fontWeight: 'bold',
    },
    td: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'center',
        color: '#333',
    },
    backButton: {
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
};

export default DetalleVenta;