import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';

const Sucursales = () => {
    const { id_cliente } = useParams();
    const [sucursales, setSucursales] = useState([]);
    const [error, setError] = useState('');
    const [cliente, setCliente] = useState('');
    const [tipoCliente, setTipoCliente] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentSucursal, setCurrentSucursal] = useState({
        razon_social: '',
        ciudad: '',
        nombre_colonia: '',
        codigo_postal: '',
        calle: '',
        numero: '',
        referencia: '',
    });

    useEffect(() => {
        const fetchSucursales = async () => {
            try {
                const response = await axios.get(`https://gavilanes.com.mx/SCRV/sucursales/obtener_sucursales.php?id_cliente=${id_cliente}`);
                const sucursalesData = response.data;

                if (sucursalesData.length > 0) {
                    setCliente(sucursalesData[0].cliente); // Toma el cliente del primer elemento del array
                    setTipoCliente(sucursalesData[0].tipo_cliente);
                }

                setSucursales(sucursalesData);
            } catch (error) {
                setError('Error al obtener las sucursales: ' + error.message);
            }
        };

        fetchSucursales();
    }, [id_cliente]);

    // Define el nombre a mostrar en el encabezado
    const nombreEncabezado = tipoCliente === 'Detalle' 
        ? sucursales[0]?.razon_social || 'Sin razón social' 
        : cliente;

    // Manejar la eliminación de sucursal
    const eliminarSucursal = async (id_sucursal) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar esta sucursal?')) {
            try {
                const response = await axios.post('https://gavilanes.com.mx/SCRV/sucursales/eliminar_sucursal.php', {
                    id_sucursal,
                });
                if (response.data.success) {
                    setSucursales(sucursales.filter(sucursal => sucursal.id_sucursal !== id_sucursal));
                } else {
                    setError('Sucursal en uso, no se puede eliminar ');
                }
            } catch (error) {
                setError('No se puede eliminar la sucursal: ');
            }
        }
    };

    // Manejar la apertura del modal para agregar o editar
    const openModal = (sucursal = null) => {
        if (sucursal) {
            setCurrentSucursal(sucursal);
            setIsEditMode(true);
        } else {
            setCurrentSucursal({
                razon_social: '',
                ciudad: '',
                nombre_colonia: '',
                codigo_postal: '',
                calle: '',
                numero: '',
                referencia: '',
            });
            setIsEditMode(false);
        }
        setIsModalOpen(true);
    };

    // Manejar el cierre del modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Manejar el cambio en los campos del formulario
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentSucursal({ ...currentSucursal, [name]: value });
    };

    // Manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                await axios.post('https://gavilanes.com.mx/SCRV/sucursales/editar_sucursal.php', {
                    ...currentSucursal,
                    id_cliente,
                });
            } else {
                await axios.post('https://gavilanes.com.mx/SCRV/sucursales/agregar_sucursal.php', {
                    ...currentSucursal,
                    id_cliente,
                });
            }
            setIsModalOpen(false);
            // Refetch sucursales
            const response = await axios.get(`https://gavilanes.com.mx/SCRV/sucursales/obtener_sucursales.php?id_cliente=${id_cliente}`);
            setSucursales(response.data);
        } catch (error) {
            setError('Error al guardar la sucursal: ' + error.message);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.headerContainer}>
                <h2 style={styles.header}>Sucursales del Cliente - {nombreEncabezado}</h2>
                {tipoCliente !== 'Detalle' && (
                    <button style={styles.addButton} onClick={() => openModal()}>
                        Agregar Sucursal
                    </button>
                )}
            </div>
            {error && <p style={styles.error}>{error}</p>}
            <div style={styles.tableContainer}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th>ID Sucursal</th>
                            <th>Razón Social</th>
                            <th>Nombre de Colonia</th>
                            <th>Código Postal</th>
                            <th>Calle</th>
                            <th>Número</th>
                            <th>Referencia</th>
                            <th>Ciudad</th>
                            {tipoCliente !== 'Detalle' && <th>Acciones</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {sucursales.map((sucursal) => (
                            <tr key={sucursal.id_sucursal}>
                                <td style={styles.td}>{sucursal.id_sucursal}</td>
                                <td style={styles.td}>{sucursal.razon_social}</td>
                                <td style={styles.td}>{sucursal.nombre_colonia}</td>
                                <td style={styles.td}>{sucursal.codigo_postal}</td>
                                <td style={styles.td}>{sucursal.calle}</td>
                                <td style={styles.td}>{sucursal.numero}</td>
                                <td style={styles.td}>{sucursal.referencia}</td>
                                <td style={styles.td}>{sucursal.ciudad}</td>
                                <td style={styles.actions}>
                                {tipoCliente !== 'Detalle' && (
                                        <>
                                            <button
                                                style={styles.editButton}
                                                onClick={() => openModal(sucursal)}
                                            >
                                                ✏️
                                            </button>
                                            <button
                                                style={styles.deleteButton}
                                                onClick={() => eliminarSucursal(sucursal.id_sucursal)}
                                            >
                                                🗑️
                                            </button>
                                        </>
                                )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel={isEditMode ? 'Editar Sucursal' : 'Agregar Sucursal'}
                style={modalStyles}
            >
                <h2>{isEditMode ? 'Editar Sucursal' : 'Agregar Sucursal'}</h2>
                <form onSubmit={handleSubmit}>
                    <label style={styles.label}>Razón Social</label>
                    <input
                        style={styles.input}
                        type="text"
                        name="razon_social"
                        value={currentSucursal.razon_social}
                        onChange={handleInputChange}
                        required
                    />
                    <label style={styles.label}>Ciudad</label>
                    <input
                        style={styles.input}
                        type="text"
                        name="ciudad"
                        value={currentSucursal.ciudad}
                        onChange={handleInputChange}
                        required
                    />
                    <label style={styles.label}>Nombre de Colonia</label>
                    <input
                        style={styles.input}
                        type="text"
                        name="nombre_colonia"
                        value={currentSucursal.nombre_colonia}
                        onChange={handleInputChange}
                        required
                    />
                    <label style={styles.label}>Código Postal</label>
                    <input
                        style={styles.input}
                        type="text"
                        name="codigo_postal"
                        value={currentSucursal.codigo_postal}
                        onChange={handleInputChange}
                    />
                    <label style={styles.label}>Calle</label>
                    <input
                        style={styles.input}
                        type="text"
                        name="calle"
                        value={currentSucursal.calle}
                        onChange={handleInputChange}
                        required
                    />
                    <label style={styles.label}>Número</label>
                    <input
                        style={styles.input}
                        type="text"
                        name="numero"
                        value={currentSucursal.numero}
                        onChange={handleInputChange}
                        required
                    />
                    <label style={styles.label}>Referencia</label>
                    <input
                        style={styles.input}
                        type="text"
                        name="referencia"
                        value={currentSucursal.referencia}
                        onChange={handleInputChange}
                        required
                    />
                    <button type="submit" style={styles.saveButton}>
                        {isEditMode ? 'Guardar Cambios' : 'Agregar Sucursal'}
                    </button>
                    <button type="button" onClick={closeModal} style={styles.cancelButton}>
                        Cancelar
                    </button>
                </form> 
            </Modal>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '1000px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    header: {
        color: '#333',
    },
    addButton: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
        transition: 'background-color 0.3s',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: '#fff',
    },
    th: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'center',
        backgroundColor: '#f2f2f2',
        color: '#333',
        fontWeight: 'bold',
    },
    td: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'center',
        color: '#333',
    },
    actions: {
        display: "flex",
        justifyContent: "center",
    },
    editButton: {
        backgroundColor: "#FFD700",
        border: "none",
        borderRadius: "4px",
        padding: "8px 12px",
        cursor: "pointer",
        marginRight: "8px",
        fontSize: "16px",
        color: "#fff",
    },
    deleteButton: {
        backgroundColor: "#FF6347",
        border: "none",
        borderRadius: "4px",
        padding: "8px 12px",
        cursor: "pointer",
        fontSize: "16px",
        color: "#fff",
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        marginBottom: '10px',
    },
    saveButton: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
        marginRight: '10px',
    },
    cancelButton: {
        backgroundColor: '#f44336',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
};

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        width: '400px',
    },
};

export default Sucursales;