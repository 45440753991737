import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Mapeo = () => {
    const { idRuta } = useParams();
    const [sucursales, setSucursales] = useState([]);
    const [ordenEntregas, setOrdenEntregas] = useState([]); // Estado para el orden de las entregas
    const [map, setMap] = useState(null);
    const [directionsService, setDirectionsService] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);
    const [ubicacionActual, setUbicacionActual] = useState(null); // Estado para la ubicación actual
    const [ubicacionMarker, setUbicacionMarker] = useState(null); // Marcador de la ubicación actual
    const [recorridoIniciado, setRecorridoIniciado] = useState(false); // Estado para iniciar el recorrido

    // Coordenadas fijas del punto inicial y final
    const puntoFijo = { lat: 20.529049, lng: -100.428674 };

    // Fetch de las direcciones desde el API
    const fetchDirecciones = async () => {
        try {
            const response = await fetch(`https://gavilanes.com.mx/SCRV/rutas/direcciones.php?id_ruta=${idRuta}`);
            const data = await response.json();

            if (data.error) {
                console.error('Error del servidor:', data.error);
                setSucursales([]);
                return;
            }

            if (data.sucursales && data.sucursales.length > 0) {
                setSucursales(
                    data.sucursales.map((sucursal) => {
                        return `${sucursal.razon_social}, ${sucursal.calle} ${sucursal.numero}, ${sucursal.nombre_colonia}, ${sucursal.codigo_postal}`;
                    })
                );
            } else {
                console.warn('No se encontraron sucursales para esta ruta.');
                setSucursales([]);
            }
        } catch (error) {
            console.error('Error al obtener direcciones:', error);
        }
    };

    useEffect(() => {
        fetchDirecciones();
    }, [idRuta]);

    useEffect(() => {
        if (window.google) {
            const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
                center: puntoFijo, // Centrar en el punto fijo
                zoom: 12,
            });

            setMap(mapInstance);
            setDirectionsService(new window.google.maps.DirectionsService());
            setDirectionsRenderer(
                new window.google.maps.DirectionsRenderer({
                    map: mapInstance,
                    suppressMarkers: true, // Evita que Google agregue los marcadores predeterminados
                })
            );
        } else {
            console.error('API de Google Maps no cargada.');
        }
    }, []);

    const calcularRutaOptima = () => {
        if (!directionsService || !directionsRenderer || sucursales.length === 0) {
            console.warn('No es posible calcular la ruta óptima.');
            return;
        }

        const waypoints = sucursales.map((direccion) => ({
            location: direccion,
            stopover: true,
        }));

        directionsService.route(
            {
                origin: puntoFijo, // Punto inicial fijo
                destination: puntoFijo, // Punto final fijo
                waypoints: waypoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
                optimizeWaypoints: true,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    directionsRenderer.setDirections(result);

                    // Obtener el orden de las entregas excluyendo el inicio y final
                    const waypointOrder = result.routes[0].waypoint_order;
                    const orden = waypointOrder.map((index) => sucursales[index]);
                    setOrdenEntregas(orden); // Solo incluye los waypoints ordenados

                    // Agregar marcadores al mapa
                    agregarMarcadores(result.routes[0], waypointOrder);
                } else {
                    console.error('Error al calcular la ruta:', status);
                }
            }
        );
    };

    const agregarMarcadores = (route, waypointOrder) => {
        if (!map) return;

        const crearIconoConNumero = (numero) => {
            const canvas = document.createElement('canvas');
            canvas.width = 40;
            canvas.height = 40;
            const ctx = canvas.getContext('2d');

            // Dibujar el círculo
            ctx.beginPath();
            ctx.arc(20, 20, 18, 0, 2 * Math.PI);
            ctx.fillStyle = 'red';
            ctx.fill();
            ctx.strokeStyle = '#FFFFFF';
            ctx.lineWidth = 2;
            ctx.stroke();

            // Dibujar el número
            ctx.fillStyle = '#FFFFFF';
            ctx.font = 'bold 16px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(numero, 20, 20);

            return {
                url: canvas.toDataURL(),
                scaledSize: new window.google.maps.Size(40, 40),
            };
        };

        new window.google.maps.Marker({
            position: route.legs[0].start_location,
            icon: crearIconoConNumero('@'),
            map: map,
        });

        waypointOrder.forEach((waypointIndex, i) => {
            const leg = route.legs[i + 1];
            new window.google.maps.Marker({
                position: leg.start_location,
                icon: crearIconoConNumero(i + 1),
                map: map,
            });
        });

        const finalLeg = route.legs[route.legs.length - 1];
        new window.google.maps.Marker({
            position: finalLeg.end_location,
            icon: crearIconoConNumero('@'),
            map: map,
        });
    };

    const rastrearUbicacion = () => {
        if (!navigator.geolocation) {
            console.error('La API de geolocalización no está soportada en este navegador.');
            return;
        }

        navigator.geolocation.watchPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                const nuevaUbicacion = { lat: latitude, lng: longitude };
                setUbicacionActual(nuevaUbicacion);

                if (ubicacionMarker) {
                    // Actualizar la posición del marcador existente
                    ubicacionMarker.setPosition(nuevaUbicacion);
                } else if (map) {
                    // Crear un marcador nuevo si no existe
                    const marker = new window.google.maps.Marker({
                        position: nuevaUbicacion,
                        map: map,
                        icon: {
                            url: 'https://img.icons8.com/color/48/000000/street-view.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        },
                    });
                    setUbicacionMarker(marker);
                }

                // Centrar el mapa solo si el recorrido está iniciado
                if (recorridoIniciado && map) {
                    map.setCenter(nuevaUbicacion);
                    map.setZoom(15); // Ajustar el zoom al centrarse
                }
            },
            (error) => {
                console.error('Error obteniendo la ubicación:', error);
            },
            { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 }
        );
    };

    // Nuevo useEffect que rastrea la ubicación después de inicializar el mapa
    useEffect(() => {
        if (map && recorridoIniciado) {
            rastrearUbicacion();
        }
    }, [map, recorridoIniciado]);

    useEffect(() => {
        if (sucursales.length > 0) {
            calcularRutaOptima();
        }
    }, [sucursales, directionsService, directionsRenderer]);

    return (
        <div style={{ padding: '1rem', fontFamily: 'Arial, sans-serif' }}>
            <h2 style={{ textAlign: 'center' }}>Mapeo de Ruta</h2>
            <div id="map" style={{ width: '100%', height: '370px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}></div>
            {sucursales.length === 0 && <p style={{ textAlign: 'center', marginTop: '1rem' }}>No hay sucursales disponibles para mostrar en el mapa.</p>}
            {ordenEntregas.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                    <h3 style={{ textAlign: 'center' }}>Orden de Entregas:</h3>
                    <ol style={{ padding: '0 2rem', listStyle: 'decimal inside' }}>
                        {ordenEntregas.map((direccion, index) => (
                            <li key={index} style={{ marginBottom: '0.5rem' }}>{direccion}</li>
                        ))}
                    </ol>
                </div>
            )}
            <button 
                onClick={() => setRecorridoIniciado(true)} 
                style={{ 
                    display: 'block', 
                    margin: '1rem auto', 
                    padding: '0.75rem 1.5rem', 
                    backgroundColor: '#007BFF', 
                    color: '#FFF', 
                    border: 'none', 
                    borderRadius: '4px', 
                    fontSize: '1rem', 
                    cursor: 'pointer', 
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s ease'
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
                onMouseOut={(e) => e.target.style.backgroundColor = '#007BFF'}
            >
                Iniciar recorrido
            </button>
        </div>
    );
};

export default Mapeo;
