import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

const DetalleProducto = () => {
    const { id } = useParams();
    const [producto, setProducto] = useState(null);
    const [precios, setPrecios] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [newPrices, setNewPrices] = useState({
        precio_publico: '',
        precio_menudeo: '',
        precio_medio_mayoreo: '',
        precio_mayoreo: '',
        precio_mega_mayoreo: '',
        precio_minimo: '',
        fecha_vigencia: '',
        fecha_registro: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                navigate('/login');
            } else if (user.tipo_usuario != '1') {
                navigate('/rutas'); // Redirige a la vista "rutas" si el tipo de usuario es '2'
            }
        }, [navigate]);

    useEffect(() => {
        const obtenerProducto = async () => {
            try {
                const response = await fetch(`https://gavilanes.com.mx/SCRV/productos/detalleprod.php?id=${id}`);
                const producto = await response.json();
                setProducto(producto);
            } catch (error) {
                console.error('Error al cargar el producto:', error);
            }
        };
        obtenerProducto();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPrices((prevPrices) => ({ ...prevPrices, [name]: value }));
    };

    const fetchPrecios = async () => {
        try {
            const response = await fetch(`https://gavilanes.com.mx/SCRV/precios/precios_general.php?id_producto=${id}`);
            const precios = await response.json();
            setPrecios(precios.length > 0 ? precios : null);
        } catch (error) {
            console.error('Error al cargar los precios:', error);
        }
    };

    const addPrecios = async () => {
        try {
            const response = await fetch(`https://gavilanes.com.mx/SCRV/precios/agregar_precio.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...newPrices, id_producto: id }),
            });
            if (response.ok) {
                fetchPrecios(); // Refresh prices
                setIsAddModalOpen(false);
            }
        } catch (error) {
            console.error('Error al agregar precios:', error);
        }
    };

    const editPrecios = async () => {
        try {
            const response = await fetch(`https://gavilanes.com.mx/SCRV/precios/editar_precio.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...newPrices, id_producto: id }),
            });
            if (response.ok) {
                fetchPrecios(); // Refresh prices
                setIsEditModalOpen(false);
            }
        } catch (error) {
            console.error('Error al editar precios:', error);
        }
    };

    useEffect(() => {
        if (isEditModalOpen) fetchPrecios();
    }, [isEditModalOpen]);

    useEffect(() => {
        fetchPrecios();
    }, []);

    if (!producto) return <p>Cargando...</p>;

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.title}>{producto.nombre_producto}</h2>
                <img
                    src={`https://gavilanes.com.mx/SCRV/productos/${producto.imagen}`}
                    alt={producto.nombre_producto}
                    style={styles.image}
                />
                <div style={styles.details}>
                    <div style={styles.field}>
                        <span style={styles.label}>Código de Barras:</span>
                        <span style={styles.value}>{producto.codigo_barras}</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Piezas por Caja:</span>
                        <span style={styles.value}>{producto.piezaxcaja}</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Gramaje:</span>
                        <span style={styles.value}>{producto.gramaje} g</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Peso Bruto:</span>
                        <span style={styles.value}>{producto.peso_bruto} g</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Peso Neto:</span>
                        <span style={styles.value}>{producto.peso_neto} g</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Capacidad del Frasco:</span>
                        <span style={styles.value}>{producto.capacidad_frasco} ml</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Código de Tapa:</span>
                        <span style={styles.value}>{producto.codigo_tapa}</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Altura:</span>
                        <span style={styles.value}>{producto.altura} cm</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Anchura:</span>
                        <span style={styles.value}>{producto.anchura} cm</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Peso de la Caja:</span>
                        <span style={styles.value}>{producto.peso_caja} g</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Vida en Anaquel:</span>
                        <span style={styles.value}>{producto.vida_anaquel} días</span>
                    </div>
                    <div style={styles.field}>
                        <span style={styles.label}>Costo de Producción:</span>
                        <span style={styles.value}>${producto.costo_produccion}</span>
                    </div>
                </div>
                <button
                    onClick={
                        precios
                            ? () => setIsEditModalOpen(true)
                            : () => setIsAddModalOpen(true)
                    }
                    style={styles.button}
                >
                    {precios ? 'Ver/Editar Precios' : 'Agregar Precios'}
                </button>
            </div>
    
            {/* Modal para agregar precios */}
            <Modal isOpen={isAddModalOpen} onRequestClose={() => setIsAddModalOpen(false)} style={styles.modal}>
                <h2>Agregar Precios</h2>
                <div style={styles.modalContent}>
                    <form style={styles.form}>
                        {Object.keys(newPrices).map((key) => (
                            <div key={key} style={styles.modalField}>
                                <label style={styles.label}>{key.replace(/_/g, ' ')}:</label>
                                <input
                                    type={key.includes('fecha') ? 'date' : 'number'}
                                    name={key}
                                    value={newPrices[key]}
                                    onChange={handleInputChange}
                                    style={styles.input}
                                />
                            </div>
                        ))}
                        <button type="button" onClick={addPrecios} style={styles.modalButton}>Guardar Precios</button>
                        <button type="button" onClick={() => setIsAddModalOpen(false)} style={styles.cancelButton}>Cancelar</button>
                    </form>
                </div>
            </Modal>
    
            {/* Modal para ver/editar precios */}
            <Modal isOpen={isEditModalOpen} onRequestClose={() => setIsEditModalOpen(false)} style={styles.modal}>
                <h2>Ver/Editar Precios</h2>
                <div style={styles.modalContent}>
                    {precios ? (
                        <form style={styles.form}>
                            {Object.keys(precios[0] || {}).map((key) => (
                                <div key={key} style={styles.modalField}>
                                    <label style={styles.label}>{key.replace(/_/g, ' ')}:</label>
                                    <input
                                        type={key.includes('fecha') ? 'date' : 'number'}
                                        name={key}
                                        value={newPrices[key] || precios[0][key]}
                                        onChange={handleInputChange}
                                        style={styles.input}
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={editPrecios} style={styles.modalButton}>Guardar Cambios</button>
                            <button type="button" onClick={() => setIsEditModalOpen(false)} style={styles.cancelButton}>Cancelar</button>
                        </form>
                    ) : (
                        <p>Cargando precios...</p>
                    )}
                </div>
            </Modal>
        </div>
    );    
};

const styles = {
    container: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    card: {
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        maxWidth: '800px',
        width: '100%',
        backgroundColor: 'white',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    image: {
        display: 'block',
        margin: '0 auto 20px auto',
        maxWidth: '200px',
        maxHeight: '200px',
        objectFit: 'contain',
    },
    details: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Two columns
        gap: '15px 30px',
        fontSize: '16px',
        color: '#555',
    },
    button: {
        margin: '20px auto 0 auto',
        padding: '10px 20px',
        display: 'block',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'center',
    },
    modal: {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '600px',
            padding: '30px',
            borderRadius: '10px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            overflow: 'auto', // Enable scrolling
            maxHeight: '90vh',
        }
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    modalField: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '5px',
    },
    value: {
        color: '#555',
    },
    input: {
        padding: '8px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    modalButton: {
        marginTop: '15px',
        padding: '10px',
        fontSize: '16px',
        color: 'white',
        backgroundColor: '#4CAF50',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    cancelButton: {
        padding: '10px',
        fontSize: '16px',
        color: '#555',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ddd',
        borderRadius: '5px',
        cursor: 'pointer'
    }
};

export default DetalleProducto;
