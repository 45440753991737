import React, { useState, useEffect } from 'react';
import { FaTrash, FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Rutas = () => {
    const [rutas, setRutas] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tipoCliente, setTipoCliente] = useState('');
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [idSucursalSeleccionado, setIdSucursalSeleccionado] = useState('');
    const navigate = useNavigate();

    const [usuario, setUsuario] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');  // Si no hay usuario, redirige al login
        } else {
            setUsuario(user);  // Si hay usuario, guarda los datos en el estado
        }
    }, [navigate]);

    const handleMapeo = (idRuta) => {
        navigate(`/mapeo/${idRuta}`);
    };

    const [formConveniencia, setFormConveniencia] = useState({
        ciudad: '',
        razonSocial: '',
        colonia: '',
        codigoPostal: '',
        calle: '',
        numero: '',
        referencia: '',
        nombreCliente: '',
    });
    const [clientes, setClientes] = useState([]);
    const [filteredClientes, setFilteredClientes] = useState([]);

    const fetchRutas = async () => {
        try {
            const response = await fetch('https://gavilanes.com.mx/SCRV/rutas/obtener_rutas.php');
            const data = await response.json();
            const rutasFormateadas = data.map((ruta) => ({
                id: ruta.id_rutas,
                nombre: ruta.nombre_ruta,
                fecha: ruta.fecha,
            }));
            setRutas(rutasFormateadas);
        } catch (error) {
            console.error('Error al obtener las rutas:', error);
        }
    };

    useEffect(() => {
        fetchRutas();
    }, []);

    const handleVerDetalles = (id) => {
        navigate(`/detalleRuta/${id}`);
    };

    const handleRegistrarRuta = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTipoCliente('');
        setRazonesSociales([]);
        setIdSucursalSeleccionado('');
        setFormConveniencia({
            ciudad: '',
            razonSocial: '',
            colonia: '',
            codigoPostal: '',
            calle: '',
            numero: '',
            referencia: '',
            nombreCliente: '',
        });
        setClientes([]);
        setFilteredClientes([]);
    };

    const handleTipoClienteClick = async (tipo) => {
        setTipoCliente(tipo);
        if (tipo === 'detalle') {
            try {
                const response = await fetch('https://gavilanes.com.mx/SCRV/rutas/razon_social.php');
                const data = await response.json();
                const razonesFormateadas = data.map((razon) => ({
                    id_sucursal: razon.id_sucursal,
                    razon_social: razon.razon_social,
                }));
                setRazonesSociales(razonesFormateadas);
            } catch (error) {
                console.error('Error al obtener razones sociales:', error);
            }
        } else if (tipo === 'conveniencia') {
            try {
                const response = await fetch('https://gavilanes.com.mx/SCRV/clientes/obtener_clientes.php');
                const data = await response.json();
                setClientes(data); // Guarda los clientes solo cuando selecciona "Conveniencia"
            } catch (error) {
                console.error('Error al obtener los clientes:', error);
            }
        }
    };
    

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormConveniencia((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
        if (name === 'nombreCliente') {
            filterClientes(value);
        }
    };

    const filterClientes = (query) => {
        if (!query) {
            setFilteredClientes([]); // Limpiar resultados si no hay consulta
            return;
        }
        // Filtrar clientes por coincidencia con el nombre ingresado (query)
        const filtered = clientes.filter((cliente) =>
            cliente.cliente && cliente.cliente.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredClientes(filtered);
    };
    
    const fetchClientes = async () => {
        try {
            const response = await fetch('https://gavilanes.com.mx/SCRV/clientes/obtener_clientes.php');
            const data = await response.json();
            // Guardar la lista completa de clientes al cargar la página
            setClientes(data);
        } catch (error) {
            console.error('Error al obtener los clientes:', error);
        }
    };
    
    const selectCliente = (cliente) => {
        setFormConveniencia((prevForm) => ({
            ...prevForm,
            nombreCliente: cliente.cliente, // Usar la propiedad "cliente"
        }));
        setFilteredClientes([]); // Limpiar sugerencias
    };
    

    const handleRegistrarRutaConTienda = async () => {
        if (!tipoCliente) {
            alert('Por favor selecciona el tipo de cliente.');
            return;
        }
        if (tipoCliente === 'detalle' && !idSucursalSeleccionado) {
            alert('Por favor selecciona una razón social.');
            return;
        }
        try {
            const requestBody = {
                tipoRuta: tipoCliente,
                idSucursal: tipoCliente === 'detalle' ? idSucursalSeleccionado : null,
                datosConveniencia: tipoCliente === 'conveniencia' ? formConveniencia : null,
            };
            const response = await fetch('https://gavilanes.com.mx/SCRV/rutas/registrar_ruta.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            });
            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    alert(data.success);
                    closeModal();
                    fetchRutas();
                } else {
                    alert(data.error || 'Ocurrió un error al registrar la ruta.');
                }
            } else {
                console.error('Error al registrar la ruta:', response.statusText);
            }
        } catch (error) {
            console.error('Error de conexión:', error);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Rutas</h2>
            {usuario && usuario.tipo_usuario === '1' && (
                <button style={styles.registerButton} onClick={handleRegistrarRuta}>
                    Registrar ruta
                </button>
            )}
            <div style={styles.listContainer}>
                <h3 style={styles.subtitle}>Lista de rutas</h3>
                <table style={styles.table}>
                <tbody>
                    {rutas.map((ruta) => (
                        <tr
                        key={ruta.id}
                        style={{ ...styles.row, ...(ruta.isHovered ? styles.rowHover : {}) }}
                        onClick={(e) => {
                            if (!e.target.closest('button')) {
                                handleVerDetalles(ruta.id);
                            }
                        }}
                        onMouseEnter={() =>
                            setRutas((prevRutas) =>
                                prevRutas.map((r) =>
                                    r.id === ruta.id ? { ...r, isHovered: true } : r
                                )
                            )
                        }
                        onMouseLeave={() =>
                            setRutas((prevRutas) =>
                                prevRutas.map((r) =>
                                    r.id === ruta.id ? { ...r, isHovered: false } : r
                                )
                            )
                        }
                    >
                        <td style={styles.nameColumn}>
                            <div>
                                <div style={styles.routeId}>Ruta {ruta.id}</div>
                                <div style={styles.routeName}>{ruta.nombre}</div>
                            </div>
                        </td>
                        <td style={styles.actionsColumn}>
                            <button style={styles.iconButton} onClick={(e) => { e.stopPropagation(); handleMapeo(ruta.id); }}>
                                <FaMapMarkerAlt style={styles.icon} /> Mapeo
                            </button>
                            {usuario && usuario.tipo_usuario === '1' && (
                                <button style={styles.iconButton} onClick={(e) => e.stopPropagation()}>
                                    <FaTrash style={styles.icon} /> Eliminar
                                </button>
                            )}
                            
                        </td>
                    </tr>
                    
                    ))}
                </tbody>

                </table>
            </div>
            {isModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modal}>
                        <h3 style={styles.modalTitle}>Selecciona el tipo de cliente</h3>
                        <div style={styles.modalButtons}>
                            <button
                                style={{
                                    ...styles.modalButton,
                                    ...(tipoCliente === 'detalle' ? styles.selectedButton : {}),
                                }}
                                onClick={() => handleTipoClienteClick('detalle')}
                            >
                                Detalle
                            </button>
                            <button
                                style={{
                                    ...styles.modalButton,
                                    ...(tipoCliente === 'conveniencia' ? styles.selectedButton : {}),
                                }}
                                onClick={() => handleTipoClienteClick('conveniencia')}
                            >
                                Otro
                            </button>
                        </div>
                        {tipoCliente === 'detalle' && (
                            <div style={styles.selectContainer}>
                                <h4>Razones Sociales:</h4>
                                <select
                                    style={styles.select}
                                    value={idSucursalSeleccionado}
                                    onChange={(e) => setIdSucursalSeleccionado(e.target.value)}
                                >
                                    <option value="">Selecciona una opción</option>
                                    {razonesSociales.map((razon) => (
                                        <option key={razon.id_sucursal} value={razon.id_sucursal}>
                                            {razon.razon_social}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {tipoCliente === 'conveniencia' && (
                            <div style={styles.scrollableForm}>
                                <form style={styles.form}>
                                {['nombreCliente', 'razonSocial','ciudad', 'colonia', 'codigoPostal', 'calle', 'numero', 'referencia'].map(
                                        (field) => (
                                            <div key={field} style={styles.inputContainer}>
                                                <label style={styles.label}>{field}</label>
                                                <input
                                                    type="text"
                                                    name={field}
                                                    value={formConveniencia[field] || ''}
                                                    onChange={handleFormChange}
                                                    style={styles.input}
                                                />
                                                {field === 'nombreCliente' && filteredClientes.length > 0 && (
                                                    <ul style={styles.suggestionsList}>
                                                        {filteredClientes.map((cliente) => (
                                                            <li
                                                                key={cliente.id_cliente}
                                                                onClick={() => selectCliente(cliente)}
                                                                style={styles.suggestionItem}
                                                            >
                                                                {cliente.cliente || 'Sin nombre'} {/* Muestra 'Sin nombre' si está vacío */}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )
                                    )}
                                </form>
                            </div>
                        )}
                        <div style={styles.modalActions}>
                            <button style={styles.registerButton} onClick={handleRegistrarRutaConTienda}>
                                Registrar
                            </button>
                            <button style={styles.cancelButton} onClick={closeModal}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    scrollableForm: {
        maxHeight: '300px',
        overflowY: 'scroll',
        padding: '10px',
    },

    suggestionsList: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        border: '1px solid #ccc',
        maxHeight: '100px',
        overflowY: 'scroll',
    },
    
    suggestionItem: {
        padding: '10px',
        cursor: 'pointer',
    },

    container: {
        backgroundColor: '#f9fafb', // Fondo claro para el contenedor principal
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '80%',
        margin: 'auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center',
        fontSize: '24px',
        marginBottom: '20px',
        fontWeight: 'bold',
        color: '#333',
    },
    registerButton: {
        padding: '12px 20px',
        backgroundColor: '#4caf50', // Verde para resaltar el botón
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
    },
    registerButtonHover: {
        backgroundColor: '#45a049',
    },
    listContainer: {
        marginTop: '20px',
    },
    subtitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#555',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '10px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px',
        backgroundColor: '#fff',
        borderBottom: '1px solid #ddd',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Añade animación para suavizar el efecto
    },
    rowHover: {
        transform: 'scale(1.02)', // Escala del zoom
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Añade una sombra para darle un efecto más elegante
    },
    
    nameColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    routeId: {
        fontSize: '14px',
        color: '#888',
    },
    routeName: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
    },
    actionsColumn: {
        display: 'flex',
        gap: '10px',
    },
    iconButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        backgroundColor: '#f1f1f1',
        padding: '8px 12px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        fontSize: '14px',
        color: '#555',
    },
    iconButtonHover: {
        backgroundColor: '#ddd',
    },
    icon: {
        fontSize: '16px',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '90%',
        maxWidth: '600px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
        animation: 'fadeIn 0.3s ease',
    },
    modalTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    modalButtons: {
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
        marginBottom: '20px',
    },
    modalButton: {
        padding: '10px 20px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
    },
    selectedButton: {
        backgroundColor: '#4caf50',
        color: '#fff',
    },
    selectContainer: {
        marginBottom: '15px',
    },
    select: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        outline: 'none',
    },
    form: {
        display: 'grid',
        gap: '15px',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    label: {
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '5px',
    },
    input: {
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        fontSize: '14px',
        outline: 'none',
        transition: 'border-color 0.3s ease',
    },
    inputFocus: {
        borderColor: '#4caf50',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    cancelButton: {
        padding: '10px 20px',
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
    },
    cancelButtonHover: {
        backgroundColor: '#d32f2f',
    },
};


export default Rutas;
