import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Productos = () => {
    const [productos, setProductos] = useState([]);
    const [hovered, setHovered] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentProducto, setCurrentProducto] = useState(null);

    const [nombreProducto, setNombreProducto] = useState('');
    const [codigoBarras, setCodigoBarras] = useState('');
    const [piezasXCaja, setPiezasXCaja] = useState('');
    const [gramaje, setGramaje] = useState('');
    const [pesoBruto, setPesoBruto] = useState('');
    const [pesoNeto, setPesoNeto] = useState('');
    const [capacidadFrasco, setCapacidadFrasco] = useState('');
    const [codigoTapa, setCodigoTapa] = useState('');
    const [altura, setAltura] = useState('');
    const [anchura, setAnchura] = useState('');
    const [pesoCaja, setPesoCaja] = useState('');
    const [vidaAnaquel, setVidaAnaquel] = useState('');
    const [costoProduccion, setCostoProduccion] = useState('');
    const [imagen, setImagen] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        } else if (user.tipo_usuario != '1') {
            navigate('/rutas'); // Redirige a la vista "rutas" si el tipo de usuario es '2'
        }
    }, [navigate]);
    

    const toggleModal = (producto = null) => {
        setCurrentProducto(producto);
        setShowModal(!showModal);

        if (!producto) {
            setNombreProducto('');
            setCodigoBarras('');
            setPiezasXCaja('');
            setGramaje('');
            setPesoBruto('');
            setPesoNeto('');
            setCapacidadFrasco('');
            setCodigoTapa('');
            setAltura('');
            setAnchura('');
            setPesoCaja('');
            setVidaAnaquel('');
            setCostoProduccion('');
            setImagen(null);
        } else {
            setNombreProducto(producto.nombre_producto);
            setCodigoBarras(producto.codigo_barras);
            setPiezasXCaja(producto.piezaxcaja);
            setGramaje(producto.gramaje);
            setPesoBruto(producto.peso_bruto);
            setPesoNeto(producto.peso_neto);
            setCapacidadFrasco(producto.capacidad_frasco);
            setCodigoTapa(producto.codigo_tapa);
            setAltura(producto.altura);
            setAnchura(producto.anchura);
            setPesoCaja(producto.peso_caja);
            setVidaAnaquel(producto.vida_anaquel);
            setCostoProduccion(producto.costo_produccion);
        }
    };

    const registrarProducto = async () => {
        const formData = new FormData();
        formData.append('nombre_producto', nombreProducto);
        formData.append('codigo_barras', codigoBarras);
        formData.append('piezaxcaja', piezasXCaja);
        formData.append('gramaje', gramaje);
        formData.append('peso_bruto', pesoBruto);
        formData.append('peso_neto', pesoNeto);
        formData.append('capacidad_frasco', capacidadFrasco);
        formData.append('codigo_tapa', codigoTapa);
        formData.append('altura', altura);
        formData.append('anchura', anchura);
        formData.append('peso_caja', pesoCaja);
        formData.append('vida_anaquel', vidaAnaquel);
        formData.append('costo_produccion', costoProduccion);
        if (imagen) {
            formData.append('imagen', imagen);
        }
        if (currentProducto) {
            formData.append('id_producto', currentProducto.id_producto);
        }

        try {
            const response = await fetch(
                `https://gavilanes.com.mx/SCRV/productos/${currentProducto ? 'editar_producto.php' : 'producto.php'}`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
            const result = await response.json();

            if (result.mensaje === 'Producto registrado' || result.mensaje === 'Producto editado') {
                obtenerProductos();
                toggleModal();
            }
        } catch (error) {
            console.error('Error al registrar o editar el producto:', error);
        }
    };

    const eliminarProducto = async (id_producto) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este producto?')) {
            try {
                const response = await fetch(`https://gavilanes.com.mx/SCRV/productos/eliminar_producto.php?id=${id_producto}`, {
                    method: 'DELETE',
                });
    
                if (!response.ok) {
                    throw new Error(`Error HTTP: ${response.status}`);
                }
    
                const result = await response.json();
    
                if (result.mensaje.includes('eliminados')) {
                    setProductos((prevProductos) =>
                        prevProductos.filter((producto) => producto.id_producto !== id_producto)
                    );
                    alert(result.mensaje);
                } else {
                    alert(result.mensaje);
                }
            } catch (error) {
                console.error('Error al eliminar el producto:', error);
                alert('Ocurrió un error al intentar eliminar el producto.');
            }
        }
    };
    
    

    const obtenerProductos = async () => {
        try {
            const response = await fetch('https://gavilanes.com.mx/SCRV/productos/obtener_productos.php?venta=2');
            const productos = await response.json();
            setProductos(productos);
        } catch (error) {
            console.error('Error al cargar productos:', error);
        }
    };

    useEffect(() => {
        obtenerProductos();
    }, []);

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Catálogo de Productos</h2>
            <button style={styles.addButton} onClick={() => toggleModal()}>Agregar Producto</button>
            <div style={styles.cardContainer}>
                {productos.map((producto) => (
                    <div
                        key={producto.id_producto}
                        style={{
                            ...styles.card,
                            ...(hovered === producto.id_producto ? styles.cardHover : {}),
                        }}
                        onMouseEnter={() => setHovered(producto.id_producto)}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => navigate(`/producto/${producto.id_producto}`)}
                    >
                        <img
                            src={`https://gavilanes.com.mx/SCRV/productos/${producto.imagen}`}
                            alt={producto.nombre_producto}
                            style={styles.productImage}
                        />
                        <h3 style={styles.productName}>{producto.nombre_producto}</h3>
                        <p style={styles.productPrice}>${producto.costo_produccion}</p>
                        <div style={styles.actions}>
                            <button
                                style={styles.iconButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleModal(producto);
                                }}
                            >
                                <FaEdit size={30} color="#000" />
                            </button>
                            <button
                                style={styles.iconButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    eliminarProducto(producto.id_producto);
                                }}
                            >
                                <FaTrash size={30} color="#000" />
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {showModal && (
                <div style={styles.modalOverlay}>
                <div style={styles.modalContent}>
                    <h2>{currentProducto ? 'Modificar Producto' : 'Registrar Producto'}</h2>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Nombre del Producto
                        </label>
                        <input
                            style={styles.input}
                            type="text"
                            value={nombreProducto}
                            onChange={(e) => setNombreProducto(e.target.value)}
                            placeholder="Nombre del Producto"
                            required
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Código de Barras
                        </label>
                        <input
                            style={styles.input}
                            type="text"
                            value={codigoBarras}
                            onChange={(e) => setCodigoBarras(e.target.value)}
                            placeholder="Código de Barras"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Piezas por Caja
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={piezasXCaja}
                            onChange={(e) => setPiezasXCaja(e.target.value)}
                            placeholder="Piezas por Caja"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Gramaje
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={gramaje}
                            onChange={(e) => setGramaje(e.target.value)}
                            placeholder="Gramaje"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Peso Bruto
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={pesoBruto}
                            onChange={(e) => setPesoBruto(e.target.value)}
                            placeholder="Peso Bruto"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Peso Neto
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={pesoNeto}
                            onChange={(e) => setPesoNeto(e.target.value)}
                            placeholder="Peso Neto"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Capacidad del Frasco
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={capacidadFrasco}
                            onChange={(e) => setCapacidadFrasco(e.target.value)}
                            placeholder="Capacidad del Frasco"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Código de la Tapa
                        </label>
                        <input
                            style={styles.input}
                            type="text"
                            value={codigoTapa}
                            onChange={(e) => setCodigoTapa(e.target.value)}
                            placeholder="Código de la Tapa"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Altura
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={altura}
                            onChange={(e) => setAltura(e.target.value)}
                            placeholder="Altura"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Anchura
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={anchura}
                            onChange={(e) => setAnchura(e.target.value)}
                            placeholder="Anchura"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Peso de la Caja
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={pesoCaja}
                            onChange={(e) => setPesoCaja(e.target.value)}
                            placeholder="Peso de la Caja"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Vida en Anaquel
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={vidaAnaquel}
                            onChange={(e) => setVidaAnaquel(e.target.value)}
                            placeholder="Vida en Anaquel"
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Costo de Producción
                        </label>
                        <input
                            style={styles.input}
                            type="number"
                            value={costoProduccion}
                            onChange={(e) => setCostoProduccion(e.target.value)}
                            placeholder="Costo de Producción"
                            required
                        />
                    </div>
                    <div style={styles.inputGroup}>
                        <label style={styles.label}>
                            Imagen del Producto
                        </label>
                        <input
                            style={styles.input}
                            type="file"
                            onChange={(e) => setImagen(e.target.files[0])}
                            required
                        />
                    </div>
                    <div style={styles.modalActions}>
                        <button style={styles.saveButton} onClick={registrarProducto}>Guardar</button>
                        <button style={styles.cancelButton} onClick={() => toggleModal()}>Cancelar</button>
                    </div>
                </div>
            </div>
            
            
            )}
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#f8f9fa',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        maxWidth: '1200px',
        margin: '20px auto',
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    label: {
        width: '150px',
        fontSize: '12px',
        marginRight: '10px',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '30px',
        textAlign: 'center',
    },
    addButton: {
        backgroundColor: '#28a745',
        color: 'white',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '30px',
        cursor: 'pointer',
        fontSize: '16px',
        display: 'block',
        margin: '0 auto 40px auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.3s ease',
    },
    cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '20px',
    },
    card: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',  // Suaviza el zoom
        textAlign: 'center',
        cursor: 'pointer',
        // Escala cuando se pasa el cursor
        transform: 'scale(1)',
    },
    cardHover: {
        transform: 'scale(1.05)',
    },
    productImage: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        marginBottom: '15px',
        transition: 'transform 0.3s ease', // Suaviza el zoom de la imagen
    },
    productName: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#34495e',
        marginBottom: '10px',
    },
    productPrice: {
        fontSize: '16px',
        color: '#2ecc71',
        marginBottom: '20px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    iconButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '5px',
        borderRadius: '5px',
        transition: 'background-color 0.3s ease',
    },
    modalOverlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
        width: '400px',
        maxHeight: '80vh',  // Limita la altura del modal al 80% de la pantalla
        overflowY: 'auto',   // Permite el desplazamiento vertical
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    saveButton: {
        backgroundColor: '#007bff',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    cancelButton: {
        backgroundColor: '#dc3545',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
};

export default Productos;
