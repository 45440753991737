import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import React, { useState, useEffect } from "react";
import Slider from "react-slick"; // Importar react-slick
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react"; // Librería de íconos
import "./App1.css"; // Importar estilos personalizados
import "./slick.css";
import "./slick-theme.css";

const App = () => {
  const [screen, setScreen] = useState("home");
  const [timelineIndex, setTimelineIndex] = useState(0);
  const [quizIndex, setQuizIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  
  const navigate = useNavigate(); // Asegúrate de que useNavigate esté dentro del componente

  const homeMusic = new Audio("/assets/music/homemusic.mp3");
  const quizMusic = new Audio("/assets/music/musicquiz.mp3");
  const farewellMusic = new Audio("/assets/music/musicfarewell.mp3");

  // Reproducir música según la pantalla activa
  useEffect(() => {
    // Función para pausar todas las canciones
    const pauseAllMusic = () => {
      homeMusic.pause();
      quizMusic.pause();
      farewellMusic.pause();
    };

    // Pausar todas las canciones antes de reproducir la correspondiente
    pauseAllMusic();

    

    // Reproducir la música correspondiente según la pantalla activa
    if (screen === "timeline") {
      homeMusic.currentTime = 0; // Reiniciar al inicio
      homeMusic.play();
    } else if (screen === "quiz") {
      quizMusic.currentTime = 0; // Reiniciar al inicio
      quizMusic.play();
    } else if (screen === "farewell") {
      farewellMusic.currentTime = 0; // Reiniciar al inicio
      farewellMusic.play();
    }

    // Limpiar la música cuando el componente se desmonte
    return () => {
      pauseAllMusic();
    };
  }, [screen]);

  const handleLogout = () => {
    localStorage.removeItem('user'); // Elimina los datos del usuario del localStorage
    navigate('/login'); // Redirige a la página de inicio de sesión
  };
  
  const handleRestart = () => {
    setScreen("home"); // Reinicia el recorrido, volviendo a la pantalla de inicio
    setTimelineIndex(0);
    setQuizIndex(0);
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
  };

  const timeline = [
    {
      date: "Primeras salidas",
      text: "----------------- Nuestras primeras salidas -----------------",
      images: [
        "/ani/primerassalidas1.jpeg",
        "/ani/primerassalidas2.jpeg",
        "/ani/primerassalidas3.jpeg",
        "/ani/primerassalidas4.jpeg"
      ],
    },
    {
      date: "Juegos",
      text: "-------------- Cuando nos la pasabamos jugando --------------",
      images: [
        "/ani/juegos1.jpeg",
        "/ani/juegos2.jpeg",
        "/ani/juegos3.jpeg"
      ],
    },
    {
      date: "Fiestas familiares",
      text: " --------- Cuando empezamos a ir a fiestas familiares --------",
      images: [
        "/ani/fiestasf1.jpeg",
        "/ani/fiestasf2.jpeg",
        "/ani/fiestasf3.jpeg"
      ],
    },
    {
        date: "Escuela de mi BB",
        text: " --------- Cuando me metía a tu escuela a escondidas --------",
        images: [
            "/ani/escuela1.jpeg",
            "/ani/escuela2.jpeg",
            "/ani/escuela3.jpeg",
            "/ani/escuela4.jpeg",
            "/ani/escuela5.jpeg",
            "/ani/escuela6.jpeg"
          ],
      },
      {
        date: "Momentos importantes",
        text: " --------- Vivimos momentos importantes juntos --------",
        images: [
            "/ani/momentoi1.jpeg",
            "/ani/momentoi2.jpeg",
            "/ani/momentoi3.jpeg",
            "/ani/momentoi4.jpeg"
          ],
      },
      {
        date: "Reforzando la relación",
        text: " --------- Saliamos juntos a cualquier lado --------",
        images: [
            "/ani/salidas1.jpeg",
            "/ani/salidas2.jpeg",
            "/ani/salidas3.jpeg",
            "/ani/salidas4.jpeg",
            "/ani/salidas5.jpeg",
            "/ani/salidas6.jpeg",
            "/ani/salidas7.jpeg",
            "/ani/salidas8.jpeg",
            "/ani/salidas9.jpeg",
            "/ani/salidas10.jpeg",
            "/ani/salidas11.jpeg",
            "/ani/salidas12.jpeg",
            "/ani/salidas14.jpeg",
            "/ani/salidas15.jpeg",
          ],
      },
      {
        date: "Amar",
        text: " --------- Me enseñaste lo que es amar --------",
        images: [
            "/ani/amar1.jpeg",
            "/ani/amar2.jpeg",
            "/ani/amar3.jpeg",
            "/ani/amar4.jpeg"
          ],
      },
      {
        date: "Sentirse amado",
        text: " --------- Y me enseñaste lo que es sentirse amado --------",
        images: [
            "/ani/sentirseamado1.jpeg",
            "/ani/sentirseamado2.jpeg",
            "/ani/sentirseamado3.jpeg"
          ],
      },
      {
        date: "Mascotas",
        text: " --------- Me enamoré hasta de tus mascotas --------",
        images: [
            "/ani/mascotas1.jpeg",
            "/ani/mascotas2.jpeg",
            "/ani/mascotas3.jpeg",
            "/ani/mascotas4.jpeg"
          ],
      },
      {
        date: "Yo niño",
        text: " --------- Quien iba a decir que este niño --------",
        images: [
            "/ani/niño1.jpeg",
            "/ani/niño2.jpeg",
            "/ani/niño3.jpeg"
          ],
      },
      {
        date: "Mi princesa",
        text: " --------- Terminaría junto a esta princesa --------",
        images: [
            "/ani/princesa1.jpeg",
            "/ani/princesa2.jpeg",
            "/ani/princesa3.jpeg",
            "/ani/princesa4.jpeg",
            "/ani/princesa5.jpeg",
            "/ani/princesa6.jpeg"
          ],
      },
  ];

  const quiz = [
    {
      question: "¿Recuerdas donde fue nuestro primer beso?",
      options: ["En el parque", "En el cine", "En tu escuela"],
      correct: 0,
    },
    {
      question: "¿Cuál es nuestra serie favorita?",
      options: ["Naruto", "Goku", "Los titanes"],
      correct: 2,
    },
    {
        question: "¿Sabes cuantos dias cumplimos de novios?",
        options: ["1000", "1095", "1235"],
        correct: 1,
    },
    {
        question: "¿Cómo te pedí ser mi novia?",
        options: ["Con mariachis, un ramo buchón y un becerrito", "En un yate privado y un anillo de diamante", "Con un peluchito en una bolsita🥹"],
        correct: 2,
    },
    {
        question: "¿Cuántos años nos llevamos de diferencia?",
        options: ["0 'Esta es la correcta'", "3", "4"],
        correct: 1,
    },
    {
        question: "¿Quién es más dramático?",
        options: ["Yo", "Tu", "Mia y Nala"],
        correct: 2,
    },
    {
        question: "¿Quién es más celos@?",
        options: ["YOOOOOO", "Tu", "Mia y Nala también"],
        correct: 0,
    },
    {
        question: "¿Última pregunta, del 1 al 10 cuánto me amas?",
        options: ["10, Es la única opción😏"],
        correct: 0,
    },
  ];

  const handleQuizAnswer = (index) => {
    if (index === quiz[quizIndex].correct) {
      setCorrectAnswers((prev) => prev + 1);
      alert("¡Correcto! 😍");
    } else {
      setIncorrectAnswers((prev) => prev + 1);
      alert("Incorrecto 🥺 La respuesta era: " + quiz[quizIndex].options[quiz[quizIndex].correct]);
    }
  
    if (quizIndex < quiz.length - 1) {
      setQuizIndex((prev) => prev + 1);
    } else {
      handleQuizCompletion();
    }
  };
  

  const NextArrow = ({ onClick }) => (
    <button className="slick-arrow next" onClick={onClick}>
      <ChevronRight size={32} />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button className="slick-arrow prev" onClick={onClick}>
      <ChevronLeft size={32} />
    </button>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleQuizCompletion = () => {
    setScreen("results");
  };  
  return (
    <div className="app-container">
      {screen === "home" && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="card">
            <div className="card-content">
              <h1 className="title">F & M</h1>
              <h1 className="title">Felices 3 años, mi amor 💖</h1>
              <p className="text">
                Gracias por todos los momentos hermosos que hemos compartido
                juntos. 🥰
              </p>
              <button onClick={() => setScreen("timeline")} className="button">
                Haz clic para ver nuestra historia
              </button>
            </div>
          </div>
        </motion.div>
      )}

      {screen === "timeline" && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="card">
            <div className="card-content">
              <h2 className="subtitle">{timeline[timelineIndex].date}</h2>
              <p className="text">{timeline[timelineIndex].text}</p>
              <Slider {...sliderSettings}>
                {timeline[timelineIndex].images.map((img, idx) => (
                  <img
                    key={idx}
                    src={img}
                    alt={`${timeline[timelineIndex].date} ${idx + 1}`}
                    className="image"
                  />
                ))}
              </Slider>
              <p></p>
              <button
                onClick={() => {
                  if (timelineIndex < timeline.length - 1) {
                    setTimelineIndex((prev) => prev + 1);
                  } else {
                    setScreen("quiz");
                  }
                }}
                className="button"
              >
                {timelineIndex < timeline.length - 1
                  ? "Siguiente"
                  : "Ir al quiz"}
              </button>
            </div>
          </div>
        </motion.div>
      )}

      {screen === "quiz" && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="card">
            <div className="card-content">
              <h2 className="subtitle">{quiz[quizIndex].question}</h2>
              <div className="options-container">
                {quiz[quizIndex].options.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleQuizAnswer(index)}
                    className="button"
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      )}

      {screen === "results" && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="card">
            <div className="card-content">
              <h2 className="subtitle">Resultados del Quiz</h2>
              <p className="text">
                Respondiste correctamente {correctAnswers} preguntas.
              </p>
              <p className="text">
                Respondiste incorrectamente {incorrectAnswers} preguntas.
              </p>
              <div className="buttons-container">
                <button
                  onClick={() => {
                    setQuizIndex(0);
                    setCorrectAnswers(0);
                    setIncorrectAnswers(0);
                    setScreen("quiz");
                  }}
                  className="button"
                >
                  Intentar de nuevo
                </button>
                <button
                  onClick={() => setScreen("farewell")}
                  className="button"
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}

        {screen === "farewell" && (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="card">
            <div className="card-content">
                <h2 className="subtitle">Gracias por ser parte de mi vida - Felices 3 años 💖</h2>
                <p className="text">
                Tengo mil cosas que agradecerte, pero en esta cartita te agradeceré por las más importantes.
                </p>
                <p className="text">
                1.- Me enseñas día a día lo que es amar y sentirse amado. Con el tiempo me has demostrado como 
                es cuando alguien te ama de verdad, me haces sentir la persona más amada del mundo.
                </p>

                <p className="text">
                2.- Me motivas a salir adelante y tener un buen futuro, me haces sentir que puedo lograr todo lo que me proponga.
                Tenerte en mi vida es la motivación más grande que tengo. Quiero que ni a ti, ni a nuestra futura familia
                les falte nada y que podamos disfrutar y hacer todo lo que nos guste juntos.
                </p>

                <p className="text">
                3.- Te has quedado conmigo a pesar de las adversidades. Llevamos 3 años juntos, solos tu y yo y hemos pasado por muchas cosas.
                A pesar de la distancia, la diferencia de edad, mis errores, todos los problemas que te llegaron, tu siempre
                has seguido conmigo. Me has ayudado a mejorar como persona y como novio.
                </p>
                
                <p className="text">
                Y podría seguir agradeciendote por muchas cosas más, pero no terminaría nunca. Así que te los demostraré
                con acciones al pasar de los días.
                </p>

                <p className="text">
                En conclusión: 
                No hay palabras para agradecer todo lo que haces y has hecho por mi. Sigamos así y mejoremos cada día más. 
                Te amo más de lo que imaginas. 💖
                </p>
                <div className="buttons-container">
                <button onClick={handleRestart} className="button">
                    Repetir todo
                </button>
                <button onClick={handleLogout} className="button">
                    Salir
                </button>
                </div>
            </div>
            </div>
        </motion.div>
        )}
    </div>
  );
};

export default App;
