import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Clientes = () => {
    const [clientes, setClientes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [filteredClientes, setFilteredClientes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [hoveredClienteId, setHoveredClienteId] = useState(null);
    const [currentCliente, setCurrentCliente] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                navigate('/login');
            } else if (user.tipo_usuario != '1') {
                navigate('/rutas'); // Redirige a la vista "rutas" si el tipo de usuario es '2'
            }
        }, [navigate]);

        const fetchClientes = async () => {
            try {
                const response = await axios.get('https://gavilanes.com.mx/SCRV/clientes/clientes.php');
                const data = Array.isArray(response.data) ? response.data : [];
                setClientes(data);
                setFilteredClientes(data); // Inicialmente, mostrar todos los clientes
            } catch (error) {
                console.error('Error fetching clients:', error);
                setClientes([]);
                setFilteredClientes([]);
            }
        };
    

    useEffect(() => {
        fetchClientes();
    }, []);

    const toggleModal = (cliente = null) => {
        setCurrentCliente(cliente);
        setShowModal(!showModal);
    };

    const handleMouseEnter = (id) => {
        setHoveredClienteId(id);
    };

    const handleMouseLeave = () => {
        setHoveredClienteId(null);
    };


    const navigateToSucursales = async (cliente) => {
        navigate(`/sucursales/${cliente}`);
    }
    

    const cambiarEstado = async (cliente) => {
        const nuevoEstado = cliente.estatus === '1' ? '0' : '1';
        try {
            await axios.post('https://gavilanes.com.mx/SCRV/clientes/cambiarEstado.php', {
                id_cliente: cliente.id_cliente,
                estatus: nuevoEstado,
            });
    
            // Actualizar tanto 'clientes' como 'filteredClientes'
            const updatedClientes = clientes.map((c) =>
                c.id_cliente === cliente.id_cliente ? { ...c, estatus: nuevoEstado } : c
            );
    
            setClientes(updatedClientes);
    
            // Filtrar los clientes con base en el término de búsqueda actual
            const filtered = updatedClientes.filter((c) =>
                Object.values(c).some((val) =>
                    val && val.toString().toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredClientes(filtered);
        } catch (error) {
            console.error('Error updating client status:', error);
        }
    };
    

    const abrirMapa = (direccion) => {
        const direccionUrl = direccion.split(' ').join('+');
        window.open(`https://www.google.com/maps/search/?api=1&query=${direccionUrl}`, '_blank');
    };

    const handleInputChange = (e) => {
        setCurrentCliente({ ...currentCliente, [e.target.name]: e.target.value });
    };

    const actualizarCliente = async () => {
        try {
            const endpoint =
                currentCliente.tipo_cliente === 'detalle'
                    ? 'actualizarClienteDetalle.php'
                    : 'actualizarCliente.php';

            await axios.post(`https://gavilanes.com.mx/SCRV/clientes/${endpoint}`, {
                ...currentCliente,
            });

            setShowModal(false);
            fetchClientes();
        } catch (error) {
            console.error('Error updating client:', error);
        }
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = clientes.filter((cliente) =>
            Object.values(cliente).some((val) =>
                val && val.toString().toLowerCase().includes(value)
            )
        );
        setFilteredClientes(filtered);
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Clientes Registrados</h2>
            <div style={styles.searchContainer}>
                <input
                    style={styles.searchInput}
                    type="text"
                    placeholder="Buscar cliente..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>
            <button style={styles.addButton} onClick={() => navigate('/registroClientes')}>
                Registrar Nuevo Cliente
            </button>
            <div style={styles.cardContainer}>
                {filteredClientes.map((cliente) => (
                    <div 
                        key={cliente.id_cliente}
                        style={{
                            ...styles.card,
                            ...(hoveredClienteId === cliente.id_cliente ? styles.cardExpanded : {}),
                        }}
                        onMouseEnter={() => handleMouseEnter(cliente.id_cliente)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => navigateToSucursales(cliente.id_cliente)}
                    >
                        <h3 style={styles.clienteName}>
                            {cliente.tipo_cliente === 'detalle' && cliente.razon_social
                                ? cliente.razon_social
                                : cliente.cliente}
                        </h3>
                        <p
                            style={
                                cliente.estatus === '1' ? styles.activeStatus : styles.inactiveStatus
                            }
                        >
                            {cliente.estatus === '1' ? 'Activo' : 'Inactivo'}
                        </p>
                        <div style={styles.actions}>
                        <button
                            style={styles.editButton}
                            onClick={(e) => {
                                e.stopPropagation(); // Detén la propagación del evento
                                toggleModal(cliente);
                            }}
                        >
                            Modificar
                        </button>
                        <button
                            style={styles.statusButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                cambiarEstado(cliente);
                            }}
                        >
                            {cliente.estatus === '1' ? 'Dar de Baja' : 'Dar de Alta'}
                        </button>
                        <button
                            style={styles.mapButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                abrirMapa(cliente.direccion);
                            }}
                        >
                            <FaMapMarkerAlt style={styles.mapIcon} />
                        </button>

                        </div>
                    </div>
                ))}
            </div>

            {showModal && (
    <div style={styles.modalOverlay}>
    <div style={styles.modalContent}>
        <h2>Modificar Cliente</h2>
        {currentCliente.tipo_cliente === 'conveniencia' ? (
            // Campos para clientes de conveniencia
            <>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Nombre del Cliente
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="cliente"
                        value={currentCliente.cliente}
                        onChange={handleInputChange}
                        placeholder="Nombre del Cliente"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Teléfono
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="telefono"
                        value={currentCliente.telefono}
                        onChange={handleInputChange}
                        placeholder="Teléfono"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Correo
                    </label>
                    <input
                        style={styles.input}
                        type="email"
                        name="correo"
                        value={currentCliente.correo}
                        onChange={handleInputChange}
                        placeholder="Correo"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Matriz
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="sucursal_matriz"
                        value={currentCliente.sucursal_matriz}
                        onChange={handleInputChange}
                        placeholder="Matriz"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Dirección
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="direccion"
                        value={currentCliente.direccion}
                        onChange={handleInputChange}
                        placeholder="Dirección"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Categoría
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="categoria"
                        value={currentCliente.categoria}
                        onChange={handleInputChange}
                        placeholder="Categoría"
                    />
                </div>
            </>
        ) : (
            // Campos para clientes de detalle
            <>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Teléfono
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="telefono"
                        value={currentCliente.telefono}
                        onChange={handleInputChange}
                        placeholder="Teléfono"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Correo
                    </label>
                    <input
                        style={styles.input}
                        type="email"
                        name="correo"
                        value={currentCliente.correo}
                        onChange={handleInputChange}
                        placeholder="Correo"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Categoría
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="categoria"
                        value={currentCliente.categoria}
                        onChange={handleInputChange}
                        placeholder="Categoría"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Razón Social
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="cliente"
                        value={currentCliente.cliente}
                        onChange={handleInputChange}
                        placeholder="Razón Social"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Nombre de Colonia
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="nombre_colonias"
                        value={currentCliente.nombre_colonias}
                        onChange={handleInputChange}
                        placeholder="Nombre de Colonia"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Código Postal
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="codigos_postales"
                        value={currentCliente.codigos_postales}
                        onChange={handleInputChange}
                        placeholder="Código Postal"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Calle
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="calles"
                        value={currentCliente.calles}
                        onChange={handleInputChange}
                        placeholder="Calle"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Número
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="numeros"
                        value={currentCliente.numeros}
                        onChange={handleInputChange}
                        placeholder="Número"
                    />
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        Referencia
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        name="referencias"
                        value={currentCliente.referencias}
                        onChange={handleInputChange}
                        placeholder="Referencia"
                    />
                </div>
            </>
        )}
        <div style={styles.modalActions}>
            <button style={styles.saveButton} onClick={actualizarCliente}>
                Guardar
            </button>
            <button style={styles.cancelButton} onClick={() => toggleModal()}>
                Cancelar
            </button>
        </div>
    </div>
</div>

)}

        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#f4f5f7',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        maxWidth: '1200px',
        margin: '20px auto',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '30px',
        textAlign: 'center',
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    label: {
        width: '150px',
        fontSize: '12px',
        marginRight: '10px',
    },
    searchContainer: { marginBottom: '15px' },
    searchInput: { width: '100%', padding: '10px', fontSize: '16px' },
    addButton: {
        backgroundColor: '#3498db',
        color: 'white',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '30px',
        cursor: 'pointer',
        fontSize: '16px',
        display: 'block',
        margin: '0 auto 40px auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.3s ease',
    },
    addButtonHover: {
        backgroundColor: '#2980b9',
    },
    cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
    },
    card: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
        cursor: 'pointer',
    },
    cardExpanded: {
        transform: 'scale(1.1)',
        boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)',
    },
    clienteName: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '10px',
    },
    activeStatus: {
        color: '#27ae60',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    inactiveStatus: {
        color: '#e74c3c',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    editButton: {
        backgroundColor: '#f39c12',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
    statusButton: {
        backgroundColor: '#e74c3c',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
    modalOverlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
        width: '400px',
        maxHeight: '80vh',
        overflowY: 'auto',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    saveButton: {
        backgroundColor: '#2ecc71',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    cancelButton: {
        backgroundColor: '#e74c3c',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    mapButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    mapIcon: {
        color: '#3498db',
        fontSize: '20px',
    },
};

export default Clientes;
