import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RegistroCliente = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        } else if (user.tipo_usuario !== '1') {
            navigate('/rutas'); // Redirige a la vista "rutas" si el tipo de usuario es '2'
        }
    }, [navigate]);

    const [tipoCliente, setTipoCliente] = useState('');
    const [formData, setFormData] = useState({
        cliente: '',
        sucursalMatriz: '',
        telefono: '',
        correo: '',
        direccion: '',
        categoria: '',
        razonSocial: '',
        ciudad: '',
        nombreColonia: '',
        codigoPostal: '',
        calle: '',
        numero: '',
        referencia: '',
    });

    const handleTipoClienteClick = (tipo) => {
        setTipoCliente(tipo);
        setFormData({
            cliente: '',
            sucursalMatriz: '',
            telefono: '',
            correo: '',
            direccion: '',
            categoria: '',
            razonSocial: '',
            ciudad: '',
            nombreColonia: '',
            codigoPostal: '',
            calle: '',
            numero: '',
            referencia: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!tipoCliente) {
            console.error("Por favor selecciona un tipo de cliente.");
            return;
        }

        try {
            const endpoint = tipoCliente === 'detalle' 
                ? 'https://gavilanes.com.mx/SCRV/clientes/registroClienteDetalle.php'
                : 'https://gavilanes.com.mx/SCRV/clientes/registroCliente.php';

            const bodyData = {
                ...formData,
                tipo_cliente: tipoCliente === 'conveniencia' ? 'Conveniencia' : 'Autoservicio',
            };

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyData),
            });

            if (response.ok) {
                console.log("Cliente registrado exitosamente");
                navigate('/clientes');
            } else {
                console.error("Error al registrar cliente");
            }
        } catch (error) {
            console.error("Error de conexión:", error);
        }
    };

    const handleBack = () => {
        navigate('/clientes');
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Registro de Cliente</h2>

            {/* Selección de tipo de cliente con diseño mejorado */}
            <div style={styles.tipoClienteContainer}>
                <h3 style={styles.subtitle}>Selecciona el tipo de cliente</h3>
                <div style={styles.tipoClienteButtons}>
                    <button
                        style={{
                            ...styles.tipoClienteButton,
                            ...(tipoCliente === 'detalle' ? styles.selectedButton : {}),
                        }}
                        onClick={() => handleTipoClienteClick('detalle')}
                    >
                        Detalle
                    </button>
                    <button
                        style={{
                            ...styles.tipoClienteButton,
                            ...(tipoCliente === 'conveniencia' ? styles.selectedButton : {}),
                        }}
                        onClick={() => handleTipoClienteClick('conveniencia')}
                    >
                        Conveniencia
                    </button>
                    <button
                        style={{
                            ...styles.tipoClienteButton,
                            ...(tipoCliente === 'autoservicio' ? styles.selectedButton : {}),
                        }}
                        onClick={() => handleTipoClienteClick('autoservicio')}
                    >
                        Autoservicio
                    </button>
                </div>
            </div>

            {/* Mostrar formulario según tipo de cliente */}
            {tipoCliente && (
                <form onSubmit={handleSubmit} style={styles.form}>
                    {(tipoCliente === 'conveniencia' || tipoCliente === 'autoservicio') && (
                        <>
                            <label style={styles.label}>Cliente</label>
                            <div style={styles.inputContainer}>
                                <input
                                    type="text"
                                    name="cliente"
                                    value={formData.cliente}
                                    onChange={handleChange}
                                    style={styles.input}
                                    required
                                />
                            </div>

                            <label style={styles.label}>Matriz</label>
                            <input
                                type="text"
                                name="sucursalMatriz"
                                value={formData.sucursalMatriz}
                                onChange={handleChange}
                                style={styles.input}
                            />

                            <label style={styles.label}>Dirección</label>
                            <input
                                type="text"
                                name="direccion"
                                value={formData.direccion}
                                onChange={handleChange}
                                style={styles.input}
                            />
                        </>
                    )}

                    <label style={styles.label}>Teléfono</label>
                    <input
                        type="tel"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleChange}
                        style={styles.input}
                    />

                    <label style={styles.label}>Correo Electrónico</label>
                    <input
                        type="email"
                        name="correo"
                        value={formData.correo}
                        onChange={handleChange}
                        style={styles.input}
                        required
                    />

                    <label style={styles.label}>Categoría</label>
                    <input
                        type="text"
                        name="categoria"
                        value={formData.categoria}
                        onChange={handleChange}
                        style={styles.input}
                    />

                    {tipoCliente === 'detalle' && (
                        <>
                            <label style={styles.label}>Razón Social</label>
                            <input
                                type="text"
                                name="razonSocial"
                                value={formData.razonSocial}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />

                            <label style={styles.label}>Ciudad</label>
                            <input
                                type="text"
                                name="ciudad"
                                value={formData.ciudad}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />

                            <label style={styles.label}>Nombre de Colonia</label>
                            <input
                                type="text"
                                name="nombreColonia"
                                value={formData.nombreColonia}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />

                            <label style={styles.label}>Código Postal</label>
                            <input
                                type="text"
                                name="codigoPostal"
                                value={formData.codigoPostal}
                                onChange={handleChange}
                                style={styles.input}
                            />

                            <label style={styles.label}>Calle</label>
                            <input
                                type="text"
                                name="calle"
                                value={formData.calle}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />

                            <label style={styles.label}>Número</label>
                            <input
                                type="text"
                                name="numero"
                                value={formData.numero}
                                onChange={handleChange}
                                style={styles.input}
                            />

                            <label style={styles.label}>Referencia</label>
                            <input
                                type="text"
                                name="referencia"
                                value={formData.referencia}
                                onChange={handleChange}
                                style={styles.input}
                            />
                        </>
                    )}

                    <div style={styles.buttonContainer}>
                        <button type="submit" style={styles.registerButton}>Registrar</button>
                        <button type="button" onClick={handleBack} style={styles.backButton}>Volver</button>
                    </div>
                </form>
            )}
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#f3f4f6',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        margin: 'auto',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        fontSize: '24px',
        textAlign: 'center',
        marginBottom: '20px',
        color: '#1f2937',
    },
    subtitle: {
        fontSize: '18px',
        textAlign: 'center',
        marginBottom: '10px',
        color: '#374151',
    },
    tipoClienteContainer: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    tipoClienteButtons: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
    },
    tipoClienteButton: {
        padding: '10px 20px',
        borderRadius: '5px',
        border: '1px solid #d1d5db',
        backgroundColor: '#f9fafb',
        cursor: 'pointer',
        fontSize: '14px',
        color: '#374151',
        transition: 'background-color 0.3s ease',
    },
    selectedButton: {
        backgroundColor: '#0070f3',
        color: '#ffffff',
        border: '1px solid #0070f3',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontSize: '14px',
        color: '#374151',
        marginBottom: '5px',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    input: {
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #d1d5db',
        marginBottom: '15px',
        fontSize: '14px',
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    registerButton: {
        padding: '12px 20px',
        backgroundColor: '#0070f3',
        color: '#ffffff',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        transition: 'background-color 0.3s ease',
    },
    backButton: {
        padding: '12px 20px',
        backgroundColor: '#e5e7eb',
        color: '#374151',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        transition: 'background-color 0.3s ease',
    },
};

export default RegistroCliente;
