import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

const HistorialAcciones = () => {
    const [acciones, setAcciones] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchAcciones = async () => {
            try {
                const response = await axios.get('https://gavilanes.com.mx/SCRV/historial_acciones/obtenerAcciones.php');
                setAcciones(response.data);
            } catch (error) {
                console.error('Error fetching actions:', error.response ? error.response.data : error.message);
            }
        };

        fetchAcciones();
    }, []);

    const filteredAcciones = acciones.filter(accion =>
        accion.nombre_usuario.toLowerCase().includes(searchTerm.toLowerCase()) ||
        accion.accion.toLowerCase().includes(searchTerm.toLowerCase()) ||
        format(new Date(accion.fecha_accion), 'dd/MM/yyyy HH:mm:ss').toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Historial de Acciones</h2>
            <input
                type="text"
                placeholder="Buscar por usuario, acción o fecha"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={styles.searchInput}
            />
            <div style={styles.tableContainer}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Usuario</th>
                            <th style={styles.th}>Acción</th>
                            <th style={styles.th}>Fecha de Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAcciones.map((accion) => (
                            <tr key={accion.id_accion}>
                                <td style={styles.td}>{accion.nombre_usuario}</td>
                                <td style={styles.td}>{accion.accion}</td>
                                <td style={styles.td}>{format(new Date(accion.fecha_accion), 'dd/MM/yyyy HH:mm:ss')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '80%',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    searchInput: {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '5px',
        border: '1px solid #ddd',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: '#fff',
    },
    th: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'left',
        backgroundColor: '#f2f2f2',
        color: '#333',
    },
    td: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'left',
        color: '#333',
    },
    tr: {
        '&:nth-child(even)': {
            backgroundColor: '#f9f9f9',
        },
    },
};

export default HistorialAcciones;